import { bindActionCreators } from 'redux';

import { IAppState } from '@store';
import fetchSessionContext from '../../actions/session-context.actions';
import { selectIsShellRerenderBlocked } from '@shared/selectors/ui.selectors';
import {
	selectBillingServiceStatus,
	selectBillingServiceStatusIsLoaded,
	selectTenantAccountIsLoaded,
} from '../../selectors/billing.selectors';
import { mainTenantProfileActionsPack } from '@platform/actions/main-tenant-profile.actions';
import { selectCMSIsLoaded } from '../../selectors/external-system-account.selectors';
import { securitySelectorsPack } from '../../selectors';
import { selectCurrencyListIsLoaded } from '@shared/selectors/currency-list';
import { mainTenantProfileSelectorsPack } from '@platform/selectors/main-tenant-profile.selectors';
import { fetchTenantAccountAction } from '@platform/actions/billing.actions';
import { selectTenantAccountDidInvalidate } from '@platform/selectors/billing.selectors';
import { selectCurrentTenantID } from '@platform/selectors/context.selectors';
import Layout, { LayoutViewProps } from './layout.view';
import { withAutoUpdate } from '@core/hocs';

const mapStateToProps = (state: IAppState) => {
	const sessionContext = state.platform.sessionContext;

	return {
		tenantID: selectCurrentTenantID(state),
		isShellRerenderBlocked: selectIsShellRerenderBlocked(state),
		sessionContext: sessionContext.item,
		sessionContextLoaded: sessionContext.item !== undefined,
		billingServiceStatus: selectBillingServiceStatus(state),
		billingServiceStatusIsLoaded: selectBillingServiceStatusIsLoaded(state),
		tenantProfile: mainTenantProfileSelectorsPack.selectAsyncTenantProfile.selectItem(state),
		tenantProfileIsLoaded: mainTenantProfileSelectorsPack.selectAsyncTenantProfile.selectIsLoaded(state),
		CMSIsLoaded: selectCMSIsLoaded(state),
		isFullAccess: securitySelectorsPack.selectIsTechService(state),
		tenantAccountIsLoaded: selectTenantAccountIsLoaded(state),
		isCurrencyListLoaded: selectCurrencyListIsLoaded(state),
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			loadSessionContext: fetchSessionContext,
			fetchTenantProfile: mainTenantProfileActionsPack.actions.fetchTenantProfile,
			fetchTenantAccount: fetchTenantAccountAction,
		},
		dispatch,
	);
}

export default withAutoUpdate<Partial<LayoutViewProps>>(
	mapStateToProps,
	mapDispatchToProps,
)({
	tenantAccountDidInvalidate: [
		selectTenantAccountDidInvalidate,
		({ tenantID, fetchTenantAccount }) => tenantID > 0 && fetchTenantAccount(tenantID),
	],
	tenantProfileDidInvalidate: [
		mainTenantProfileSelectorsPack.selectAsyncTenantProfile.selectDidInvalidate,
		({ fetchTenantProfile }) => fetchTenantProfile(),
	],
})(Layout);
