import { FetchOperationsOptions } from '@core/api/pl';
import { ComplexFilter } from '@core/api/core';
import { createDefaultPeriod } from '@utils/date';
import {
	StoreAsyncItem,
	checkAsyncAction,
	createAsyncInitialState,
	invalidateStateFromAction,
	createReducer,
	restoreValidationState,
} from '@flux';
import {
	CashflowLimitsGranulationModeCode,
	CashflowLimitsTypeModeCode,
	CellCoords,
	UnsavedCell,
} from '@cashflow-limits/models';
import { types } from '../actions/types';
import { hasBooleanKeys } from '@core/utils/object';
import { CashflowLimitUpdateOptions } from '@core/api/cashflowlimits';

export type MainCashflowLimitsState = {
	cashflowLimits: StoreAsyncItem<CashflowLimitsItemsResponse>;
	dateRange: DateRange;
	tenantEntitiesFilter: ComplexFilter;
	projectsFilter: ComplexFilter;
	searchText: string;
	granulationMode: CashflowLimitsGranulationModeCode;
	typeMode: CashflowLimitsTypeModeCode;
	isQuartersVisible: boolean;
	isYearsVisible: boolean;
	isFactVisible: boolean;
	isDeviationVisible: boolean;
	isHeaderCollapsed: boolean;
	isManualMode: boolean;
	expandedHierarchyMap: Record<string, boolean>;
	expandedColumnsMap: Record<string, boolean>;
	coords: CellCoords;
	limitCreateOptions: CashflowLimitUpdateOptions;
	detailsOptions: FetchOperationsOptions;
	detailsTitle: string;
	detailsOperations: StoreAsyncItem<Array<PLOperationBrief>>;
	unsavedCells: Array<UnsavedCell>;
	unsavedCellsLength: number;
	hasUnfinishedQueue: boolean;
};

const initialState: MainCashflowLimitsState = {
	cashflowLimits: createAsyncInitialState(null),
	dateRange: createDefaultPeriod('month'),
	tenantEntitiesFilter: new ComplexFilter(),
	projectsFilter: new ComplexFilter(),
	searchText: '',
	granulationMode: CashflowLimitsGranulationModeCode.DAYS,
	typeMode: CashflowLimitsTypeModeCode.LEGAL_ENTITY,
	isQuartersVisible: true,
	isYearsVisible: true,
	isFactVisible: true,
	isDeviationVisible: true,
	isHeaderCollapsed: false,
	isManualMode: false,
	expandedHierarchyMap: {},
	expandedColumnsMap: {},
	coords: null,
	limitCreateOptions: null,
	detailsOptions: null,
	detailsTitle: null,
	detailsOperations: createAsyncInitialState([]),
	unsavedCells: [],
	unsavedCellsLength: 0,
	hasUnfinishedQueue: false,
};

const mainCashflowLimitsReducer = createReducer<MainCashflowLimitsState>(initialState, {
	[types.INVALIDATE_DETAILS_OPERATIONS]: (action: StaticAction, state) => {
		return {
			detailsOperations: invalidateStateFromAction(action, state.detailsOperations),
		};
	},
	[types.INVALIDATE_CASHFLOW_LIMITS]: (action: StaticAction, state) => {
		return {
			cashflowLimits: invalidateStateFromAction(action, state.cashflowLimits),
		};
	},
	[types.SET_DATE_RANGE]: (action: StaticAction<DateRange>, state) => {
		return {
			dateRange: { ...action.value },
			hasUnfinishedQueue: state.unsavedCells.length > 0,
			cashflowLimits: invalidateStateFromAction(action, state.cashflowLimits),
			detailsOperations: invalidateStateFromAction(action, state.detailsOperations),
			coords: null,
			limitCreateOptions: null,
			detailsOptions: null,
			detailsTitle: null,
			expandedColumnsMap: {},
		};
	},
	[types.SET_TENANT_ENTITIES_FILTER]: (action: StaticAction<ComplexFilter>, state) => {
		return {
			tenantEntitiesFilter: action.value,
			hasUnfinishedQueue: state.unsavedCells.length > 0 && Boolean(state.tenantEntitiesFilter),
			cashflowLimits: hasBooleanKeys(action.value.value)
				? invalidateStateFromAction(action, state.cashflowLimits)
				: { ...createAsyncInitialState(null), isLoaded: true },
			detailsOperations: invalidateStateFromAction(action, state.detailsOperations),
			coords: null,
			limitCreateOptions: null,
			detailsOptions: null,
			detailsTitle: null,
		};
	},
	[types.SET_PROJECTS_FILTER]: (action: StaticAction<ComplexFilter>, state) => {
		return {
			projectsFilter: action.value,
			hasUnfinishedQueue: state.unsavedCells.length > 0 && Boolean(state.projectsFilter),
			cashflowLimits: hasBooleanKeys(action.value.value)
				? invalidateStateFromAction(action, state.cashflowLimits)
				: { ...createAsyncInitialState(null), isLoaded: true },
			detailsOperations: invalidateStateFromAction(action, state.detailsOperations),
			coords: null,
			limitCreateOptions: null,
			detailsOptions: null,
			detailsTitle: null,
		};
	},
	[types.SET_SEARCH_TEXT]: (action: StaticAction<string>) => {
		return {
			searchText: action.value,
			expandedHierarchyMap: {},
		};
	},
	[types.SET_GRANULATION_MODE]: (action: StaticAction<CashflowLimitsGranulationModeCode>, state) => {
		return {
			granulationMode: action.value,
			hasUnfinishedQueue: state.unsavedCells.length > 0,
			cashflowLimits: invalidateStateFromAction(action, state.cashflowLimits),
			coords: null,
			limitCreateOptions: null,
			detailsOptions: null,
			detailsTitle: null,
			expandedColumnsMap: {},
		};
	},
	[types.SET_TYPE_MODE]: (action: StaticAction<CashflowLimitsTypeModeCode>, state) => {
		return {
			typeMode: action.value,
			hasUnfinishedQueue: state.unsavedCells.length > 0,
			cashflowLimits:
				(action.value === CashflowLimitsTypeModeCode.LEGAL_ENTITY &&
					!hasBooleanKeys(state.tenantEntitiesFilter.value)) ||
				(action.value === CashflowLimitsTypeModeCode.PROJECT && !hasBooleanKeys(state.projectsFilter.value))
					? { ...createAsyncInitialState(null), isLoaded: true }
					: invalidateStateFromAction(action, state.cashflowLimits),
			coords: null,
			limitCreateOptions: null,
			detailsOptions: null,
			detailsTitle: null,
			expandedColumnsMap: {},
		};
	},
	[types.SET_IS_QUARTERS_VISIBLE]: (action: StaticAction<boolean>) => {
		return {
			isQuartersVisible: action.value,
		};
	},
	[types.SET_IS_YEARS_VISIBLE]: (action: StaticAction<boolean>) => {
		return {
			isYearsVisible: action.value,
		};
	},
	[types.SET_IS_FACT_VISIBLE]: (action: StaticAction<boolean>) => {
		return {
			isFactVisible: action.value,
		};
	},
	[types.SET_IS_DEVIATION_VISIBLE]: (action: StaticAction<boolean>) => {
		return {
			isDeviationVisible: action.value,
		};
	},
	[types.SET_IS_HEADER_COLLAPSED]: (action: StaticAction<boolean>) => {
		return {
			isHeaderCollapsed: action.value,
		};
	},
	[types.SET_IS_MANUAL_MODE]: (action: StaticAction<boolean>) => {
		return {
			isManualMode: action.value,
		};
	},
	[types.SET_EXPANDED_HIERARCHY_MAP]: (action: StaticAction<Record<string, boolean>>) => {
		return {
			expandedHierarchyMap: { ...action.value },
		};
	},
	[types.SET_EXPANDED_COLUMNS_MAP]: (action: StaticAction<Record<string, boolean>>) => {
		return {
			expandedColumnsMap: { ...action.value },
		};
	},
	[types.SET_SELECTED_COORDS]: (action: StaticAction<CellCoords>) => {
		return {
			coords: action.value,
		};
	},
	[types.SET_LIMIT_CREATE_OPTIONS]: (action: StaticAction<CashflowLimitUpdateOptions | null>) => {
		return {
			limitCreateOptions: action.value ? { ...action.value } : null,
		};
	},
	[types.SET_DETAILS_OPERATIONS_OPTIONS]: (action: StaticAction<FetchOperationsOptions | null>, state) => {
		const detailsOptions = action.value ? { ...action.value } : null;

		return {
			detailsOptions,
			detailsTitle: detailsOptions ? state.detailsTitle : null,
			coords: detailsOptions ? state.coords : null,
			detailsOperations: detailsOptions
				? invalidateStateFromAction(action, state.detailsOperations)
				: createAsyncInitialState([]),
		};
	},
	[types.SET_DETAILS_OPERATIONS_TITLE]: (action: StaticAction<string>, state) => {
		return {
			detailsTitle: state.detailsOptions ? action.value : null,
		};
	},
	[types.RESTORE_CASHFLOW_LIMITS_VALIDATION]: (_, state) => {
		return {
			cashflowLimits: restoreValidationState(state.cashflowLimits),
		};
	},
	[types.RESTORE_DETAILS_OPERATIONS_VALIDATION]: (_, state) => {
		return {
			detailsOperations: restoreValidationState(state.detailsOperations),
		};
	},
	[types.FETCH_CASHFLOW_LIMITS]: (action: AsyncAction<CashflowLimitsItemsResponse>, state) => {
		return {
			cashflowLimits: checkAsyncAction(action, state.cashflowLimits),
		};
	},
	[types.SET_CASHFLOW_LIMITS]: (action: StaticAction<CashflowLimitsItemsResponse>, state) => {
		const unsavedCells = state.unsavedCells.slice(state.unsavedCellsLength);
		if (state.hasUnfinishedQueue) {
			return {
				unsavedCells: [],
				hasUnfinishedQueue: false,
			};
		}
		return {
			cashflowLimits: { ...state.cashflowLimits, item: action.value },
			unsavedCells,
		};
	},
	[types.FETCH_CASHFLOW_LIMITS]: (action: AsyncAction<CashflowLimitsItemsResponse>, state) => {
		return {
			cashflowLimits: checkAsyncAction(action, state.cashflowLimits),
		};
	},
	[types.FETCH_DETAILS_OPERATIONS]: (action: AsyncAction<Array<PLOperationBrief>>, state) => {
		return {
			detailsOperations: checkAsyncAction(action, state.detailsOperations),
		};
	},
	[types.SET_UNSAVED_CELLS]: (action: StaticAction<Array<UnsavedCell>>) => {
		return {
			unsavedCells: action.value,
		};
	},
	[types.SET_UNSAVED_CELLS_LENGTH]: (action: StaticAction<number>, state) => {
		return {
			unsavedCellsLength: action.value,
		};
	},
	[types.SET_HAS_UNFINISHED_QUEUE]: (action: StaticAction<boolean>, state) => {
		return {
			hasUnfinishedQueue: action.value,
		};
	},
});

export { mainCashflowLimitsReducer };
