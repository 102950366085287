/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in InvoicePackage
 * @author YasinskiyVI Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
  /***
  * 
  */
  w.AcceptanceAct = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "ACCEPTANCE_ACT";
    this.CopySupportingElement = false;
    this.Template = null;
    this.TemplateUDFValues = [];
    this.AccountantEmployee = null;
    this.Basis = "";
    this.PageCount = -1;
    this.CounterpartyEmployee = null;
    this.Consignee = null;
    this.Shipper = null;
    this.ConsigneeAccount = null;
    this.ShipperFundsRegister = null;
    this.CargoReleaseResponsibleEmployee = null;
    this.EconomicActivityResponsibleEmployee = null;
    this.ID = -1;
    this.DateIssued = null;
    this.Number = "";
    this.Outgoing = false;
    this.SharedDocumentUID = "";
    this.SharedDocumentURL = "";
    this.UID = "";
    this.Counterparty = null;
    this.LegalEntity = null;
    this.ImportedTenant = null;
    this.Status = "";
    this.Revoked = false;
    this.ResponsibleEmployee = null;
    this.Items = [];
    this.FundsRegister = null;
    this.SignEmployee = null;
    this.CounterpartyAccount = null;
    this.LinkedDocuments = [];
    this.Contract = null;
    this.SourceDocumentLink = null;
  };
  /***
  * 
  */
  w.AcceptanceActNaturalPerson = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "ACCEPTANCE_ACT_NATURAL_PERSON";
    this.CopySupportingElement = false;
    this.NaturalPersonAddress = "";
    this.Template = null;
    this.TemplateUDFValues = [];
    this.AccountantEmployee = null;
    this.Basis = "";
    this.PageCount = -1;
    this.CounterpartyEmployee = null;
    this.Consignee = null;
    this.Shipper = null;
    this.ConsigneeAccount = null;
    this.ShipperFundsRegister = null;
    this.CargoReleaseResponsibleEmployee = null;
    this.EconomicActivityResponsibleEmployee = null;
    this.ID = -1;
    this.DateIssued = null;
    this.Number = "";
    this.Outgoing = false;
    this.SharedDocumentUID = "";
    this.SharedDocumentURL = "";
    this.UID = "";
    this.Counterparty = null;
    this.LegalEntity = null;
    this.ImportedTenant = null;
    this.Status = "";
    this.Revoked = false;
    this.ResponsibleEmployee = null;
    this.Items = [];
    this.FundsRegister = null;
    this.SignEmployee = null;
    this.CounterpartyAccount = null;
    this.LinkedDocuments = [];
    this.Contract = null;
    this.SourceDocumentLink = null;
  };
  /***
  * 
  */
  w.APIDocument = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "APIDOCUMENT";
    this.CopySupportingElement = false;
    this.Outgoing = false;
    this.Description = "";
    this.DateIssued = null;
    this.Number = "";
    this.Counterparty = null;
    this.LegalEntity = null;
    this.LegalEntityAccount = null;
    this.UID = "";
    this.SignEmployee = null;
    this.Items = [];
    this.AdvancedRequisites = {};
    this.DocumentType = "";
    this.LinkedDocuments = [];
    this.SharedDocumentUID = "";
    this.SharedDocumentURL = "";
  };
  /***
  * 
  */
  w.APIInvoice = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "APIINVOICE";
    this.CopySupportingElement = false;
    this.Outgoing = false;
    this.Description = "";
    this.RequiredPaymentDate = null;
    this.DateIssued = null;
    this.Number = "";
    this.Counterparty = null;
    this.LegalEntityAccount = null;
    this.InvoiceUID = "";
    this.LegalEntity = null;
    this.SignEmployee = null;
    this.Items = [];
    this.Status = "";
    this.CashTransactions = [];
    this.ExternalOrderUID = "";
    this.ResponsibleEmployee = null;
    this.SharedDocumentUID = "";
    this.SharedDocumentURL = "";
    this.InvoicePaymentPageURL = "";
    this.InvoicePageLink = "";
    this.Tag = "";
    this.AccrualIdentifier = "";
  };
  /***
  * 
  */
  w.APIInvoiceItem = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "APIINVOICE_ITEM";
    this.CopySupportingElement = false;
    this.ItemAmount = -1;
    this.Description = "";
    this.ItemAmountTotal = -1;
    this.ItemPrice = -1;
    this.ItemTaxRate = -1;
    this.Qty = -1;
    this.InvoiceItemUID = "";
  };
  /***
  * 
  */
  w.Contract = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "CONTRACT";
    this.CopySupportingElement = false;
    this.ContractSubject = "";
    this.Template = null;
    this.TemplateUDFValues = [];
    this.AccountantEmployee = null;
    this.Basis = "";
    this.PageCount = -1;
    this.CounterpartyEmployee = null;
    this.Consignee = null;
    this.Shipper = null;
    this.ConsigneeAccount = null;
    this.ShipperFundsRegister = null;
    this.CargoReleaseResponsibleEmployee = null;
    this.EconomicActivityResponsibleEmployee = null;
    this.ID = -1;
    this.DateIssued = null;
    this.Number = "";
    this.Outgoing = false;
    this.SharedDocumentUID = "";
    this.SharedDocumentURL = "";
    this.UID = "";
    this.Counterparty = null;
    this.LegalEntity = null;
    this.ImportedTenant = null;
    this.Status = "";
    this.Revoked = false;
    this.ResponsibleEmployee = null;
    this.Items = [];
    this.FundsRegister = null;
    this.SignEmployee = null;
    this.CounterpartyAccount = null;
    this.LinkedDocuments = [];
    this.Contract = null;
    this.SourceDocumentLink = null;
  };
  /***
  * 
  */
  w.ContractBrief = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "CONTRACT_BRIEF";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Number = "";
    this.ContractDate = null;
    this.ContractSubject = "";
    this.UID = "";
    this.Outgoing = false;
  };
  /***
  * 
  */
  w.ContractFilter = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "CONTRACT_FILTER";
    this.CopySupportingElement = false;
    this.CounterpartyIDList = [];
    this.QueryString = "";
    this.Outgoing = -1;
  };
  /***
  * 
  */
  w.DeliveryNote = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "DELIVERY_NOTE";
    this.CopySupportingElement = false;
    this.Template = null;
    this.TemplateUDFValues = [];
    this.AccountantEmployee = null;
    this.Basis = "";
    this.PageCount = -1;
    this.CounterpartyEmployee = null;
    this.Consignee = null;
    this.Shipper = null;
    this.ConsigneeAccount = null;
    this.ShipperFundsRegister = null;
    this.CargoReleaseResponsibleEmployee = null;
    this.EconomicActivityResponsibleEmployee = null;
    this.ID = -1;
    this.DateIssued = null;
    this.Number = "";
    this.Outgoing = false;
    this.SharedDocumentUID = "";
    this.SharedDocumentURL = "";
    this.UID = "";
    this.Counterparty = null;
    this.LegalEntity = null;
    this.ImportedTenant = null;
    this.Status = "";
    this.Revoked = false;
    this.ResponsibleEmployee = null;
    this.Items = [];
    this.FundsRegister = null;
    this.SignEmployee = null;
    this.CounterpartyAccount = null;
    this.LinkedDocuments = [];
    this.Contract = null;
    this.SourceDocumentLink = null;
  };
  /***
  * 
  */
  w.FinancialDocument = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "FINANCIAL_DOCUMENT";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.DateIssued = null;
    this.Number = "";
    this.Outgoing = false;
    this.SharedDocumentUID = "";
    this.SharedDocumentURL = "";
    this.UID = "";
    this.Counterparty = null;
    this.LegalEntity = null;
    this.ImportedTenant = null;
    this.Status = "";
    this.Revoked = false;
    this.ResponsibleEmployee = null;
    this.Items = [];
    this.FundsRegister = null;
    this.SignEmployee = null;
    this.CounterpartyAccount = null;
    this.LinkedDocuments = [];
    this.Contract = null;
    this.SourceDocumentLink = null;
  };
  /***
  * 
  */
  w.FinancialDocumentBundle = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "FINANCIAL_DOCUMENT_BUNDLE";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.RegDate = null;
    this.EmployeeID = -1;
    this.Description = "";
    this.TreasuryApplicationID = -1;
    this.Documents = [];
    this.Confirmed = false;
  };
  /***
  * 
  */
  w.FinancialDocumentFilter = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "FINANCIAL_DOCUMENT_FILTER";
    this.CopySupportingElement = false;
    this.PeriodDateStart = null;
    this.PeriodDateEnd = null;
    this.CounterpartyIDList = [];
    this.SearchQuery = "";
    this.Revoked = false;
    this.FinancialDocumentType = "";
    this.Number = "";
    this.LegalEntityIDList = [];
    this.Direction = -1;
  };
  /***
  * 
  */
  w.FinancialDocumentItem = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "FINANCIAL_DOCUMENT_ITEM";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.ItemAmount = -1;
    this.Description = "";
    this.BudgetItemTags = [];
    this.ItemAmountTotal = -1;
    this.ItemPrice = -1;
    this.ItemTaxRate = -1;
    this.Qty = -1;
    this.ResponsibilityCenter = null;
    this.MeasureUnit = null;
    this.Product = null;
    this.Code = "";
  };
  /***
  * 
  */
  w.FinancialDocumentLink = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "FINANCIAL_DOCUMENT_LINK";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.SynchItems = false;
    this.LinkedDocument = null;
  };
  /***
  * 
  */
  w.FinancialDocumentReference = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "FINANCIAL_DOCUMENT_REFERENCE";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Number = "";
    this.SharedDocumentUID = "";
    this.UID = "";
    this.DocumentType = null;
    this.DocumentDate = null;
  };
  /***
  * 
  */
  w.FinancialDocumentStatistics = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "FINANCIAL_DOCUMENT_STATISTICS";
    this.CopySupportingElement = false;
    this.FinancialDocumentType = null;
    this.OutgoingCount = -1;
    this.IncomingCount = -1;
    this.OutgoingAmount = -1;
    this.IncomingAmount = -1;
    this.OutgoingPaidAmount = -1;
    this.IncomingPaidAmount = -1;
    this.DateStart = null;
    this.DateFinish = null;
  };
  /***
  * 
  */
  w.FinancialDocumentStatisticsRequest = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "FINANCIAL_DOCUMENT_STATISTICS_REQUEST";
    this.CopySupportingElement = false;
    this.DateStart = null;
    this.DateFinish = null;
    this.LimitedPeriod = false;
    this.FinancialDocumentType = "";
  };
  /***
  * 
  */
  w.FinancialDocumentTemplate = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "FINANCIAL_DOCUMENT_TEMPLATE";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
    this.Body = "";
    this.FinancialDocumentType = null;
    this.UserDefinedFields = [];
    this.VersionID = -1;
    this.DefaultTemplate = false;
  };
  /***
  * 
  */
  w.FinancialDocumentTemplateUDF = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "FINANCIAL_DOCUMENT_TEMPLATE_UDF";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.FieldName = "";
  };
  /***
  * 
  */
  w.FinancialDocumentType = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "FINANCIAL_DOCUMENT_TYPE";
    this.CopySupportingElement = false;
    this.PageOrientation = -1;
    this.DefaultBody = "";
    this.Code = "";
    this.ID = -1;
    this.Name = "";
    this.GUID = "";
  };
  /***
  * 
  */
  w.FinDocAttributeValue = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "FIN_DOC_ATTRIBUTE_VALUE";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.AttributeID = -1;
    this.DateValue = null;
    this.LongValue = -1;
    this.StringValue = "";
  };
  /***
  * 
  */
  w.FinDocTemplateUDFValue = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "FIN_DOC_TEMPLATE_UDFVALUE";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.TemplateUDF = null;
    this.Value = "";
  };
  /***
  * 
  */
  w.Invoice = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "INVOICE";
    this.CopySupportingElement = false;
    this.Project = null;
    this.ResponsibilityCenter = null;
    this.Description = "";
    this.RequiredPaymentDate = null;
    this.Payments = [];
    this.IssuerBankSystemAccountGUID = "";
    this.ExternalOrderUID = "";
    this.CallbackParametersString = "";
    this.DealLink = "";
    this.ExpectedPaymentDate = null;
    this.PipeLineStage = null;
    this.PaymentProbability = -1;
    this.Tag = "";
    this.AccrualIdentifier = "";
    this.ID = -1;
    this.DateIssued = null;
    this.Number = "";
    this.Outgoing = false;
    this.SharedDocumentUID = "";
    this.SharedDocumentURL = "";
    this.UID = "";
    this.Counterparty = null;
    this.LegalEntity = null;
    this.ImportedTenant = null;
    this.Status = "";
    this.Revoked = false;
    this.ResponsibleEmployee = null;
    this.Items = [];
    this.FundsRegister = null;
    this.SignEmployee = null;
    this.CounterpartyAccount = null;
    this.LinkedDocuments = [];
    this.Contract = null;
    this.SourceDocumentLink = null;
  };
  /***
  * 
  */
  w.InvoiceChangeNotification = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "INVOICE_CHANGE_NOTIFICATION";
    this.CopySupportingElement = false;
    this.InvoiceID = -1;
    this.StatusChanged = false;
    this.ContentChanged = false;
  };
  /***
  * 
  */
  w.InvoiceChangeNotificationList = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "INVOICE_CHANGE_NOTIFICATION_LIST";
    this.CopySupportingElement = false;
    this.Notifications = [];
  };
  /***
  * 
  */
  w.InvoiceFact = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "INVOICE_FACT";
    this.CopySupportingElement = false;
    this.ShippingDocument = "";
    this.PaymentDocument = "";
    this.Template = null;
    this.TemplateUDFValues = [];
    this.AccountantEmployee = null;
    this.Basis = "";
    this.PageCount = -1;
    this.CounterpartyEmployee = null;
    this.Consignee = null;
    this.Shipper = null;
    this.ConsigneeAccount = null;
    this.ShipperFundsRegister = null;
    this.CargoReleaseResponsibleEmployee = null;
    this.EconomicActivityResponsibleEmployee = null;
    this.ID = -1;
    this.DateIssued = null;
    this.Number = "";
    this.Outgoing = false;
    this.SharedDocumentUID = "";
    this.SharedDocumentURL = "";
    this.UID = "";
    this.Counterparty = null;
    this.LegalEntity = null;
    this.ImportedTenant = null;
    this.Status = "";
    this.Revoked = false;
    this.ResponsibleEmployee = null;
    this.Items = [];
    this.FundsRegister = null;
    this.SignEmployee = null;
    this.CounterpartyAccount = null;
    this.LinkedDocuments = [];
    this.Contract = null;
    this.SourceDocumentLink = null;
  };
  /***
  * 
  */
  w.InvoiceForApplication = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "INVOICE_FOR_APPLICATION";
    this.CopySupportingElement = false;
    this.TreasuryApplicationID = -1;
    this.Items = [];
    this.InvoiceDateIssued = null;
    this.InvoiceID = -1;
  };
  /***
  * 
  */
  w.InvoiceItemForApplication = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "INVOICE_ITEM_FOR_APPLICATION";
    this.CopySupportingElement = false;
    this.InvoiceItem = null;
    this.ApplicationItem = null;
  };
  /***
  * 
  */
  w.InvoiceNotificationEvent = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "INVOICE_NOTIFICATION_EVENT";
    this.CopySupportingElement = false;
    this.InvoiceID = -1;
    this.Sender = "";
    this.Recipient = "";
    this.EventDate = null;
    this.ID = -1;
  };
  /***
  * 
  */
  w.InvoicePayment = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "INVOICE_PAYMENT";
    this.CopySupportingElement = false;
    this.PaymentAmount = -1;
    this.ID = -1;
    this.PaymentDate = null;
    this.PaymentState = null;
  };
  /***
  * 
  */
  w.InvoicePaymentNotification = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "INVOICE_PAYMENT_NOTIFICATION";
    this.CopySupportingElement = false;
    this.SourcePayment = null;
    this.IssuerTenantID = -1;
    this.InvoiceSharedDocumentUID = "";
    this.IssuerUserID = -1;
  };
  /***
  * 
  */
  w.TemplatedFinancialDocument = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "TEMPLATED_FINANCIAL_DOCUMENT";
    this.CopySupportingElement = false;
    this.Template = null;
    this.TemplateUDFValues = [];
    this.AccountantEmployee = null;
    this.Basis = "";
    this.PageCount = -1;
    this.CounterpartyEmployee = null;
    this.Consignee = null;
    this.Shipper = null;
    this.ConsigneeAccount = null;
    this.ShipperFundsRegister = null;
    this.CargoReleaseResponsibleEmployee = null;
    this.EconomicActivityResponsibleEmployee = null;
    this.ID = -1;
    this.DateIssued = null;
    this.Number = "";
    this.Outgoing = false;
    this.SharedDocumentUID = "";
    this.SharedDocumentURL = "";
    this.UID = "";
    this.Counterparty = null;
    this.LegalEntity = null;
    this.ImportedTenant = null;
    this.Status = "";
    this.Revoked = false;
    this.ResponsibleEmployee = null;
    this.Items = [];
    this.FundsRegister = null;
    this.SignEmployee = null;
    this.CounterpartyAccount = null;
    this.LinkedDocuments = [];
    this.Contract = null;
    this.SourceDocumentLink = null;
  };
  /***
  * 
  */
  w.UniTransferDoc = function() {
    this.PACKAGE = "INVOICE";
    this.CLASSIFIER = "UNI_TRANSFER_DOC";
    this.CopySupportingElement = false;
    this.UniTransferDocumentStatus = -1;
    this.ShippingDocument = "";
    this.PaymentDocument = "";
    this.Template = null;
    this.TemplateUDFValues = [];
    this.AccountantEmployee = null;
    this.Basis = "";
    this.PageCount = -1;
    this.CounterpartyEmployee = null;
    this.Consignee = null;
    this.Shipper = null;
    this.ConsigneeAccount = null;
    this.ShipperFundsRegister = null;
    this.CargoReleaseResponsibleEmployee = null;
    this.EconomicActivityResponsibleEmployee = null;
    this.ID = -1;
    this.DateIssued = null;
    this.Number = "";
    this.Outgoing = false;
    this.SharedDocumentUID = "";
    this.SharedDocumentURL = "";
    this.UID = "";
    this.Counterparty = null;
    this.LegalEntity = null;
    this.ImportedTenant = null;
    this.Status = "";
    this.Revoked = false;
    this.ResponsibleEmployee = null;
    this.Items = [];
    this.FundsRegister = null;
    this.SignEmployee = null;
    this.CounterpartyAccount = null;
    this.LinkedDocuments = [];
    this.Contract = null;
    this.SourceDocumentLink = null;
  };
   
   
  
  /***
   * Client API to FinancialDocumentServiceWS web service
   */
  w.FinancialDocumentServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "FinancialDocumentServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param document [DEFAULT!!DEFAULT]
   * @param bundleID [DEFAULT!Invoice bundle owning added invoice, if value is less or equal to zero invoice is added without any bundle!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.addDocument = function(document, bundleID, callbackHandler) {
    var parameterNames = ["document", "bundleID"];
    var documentJSON = JSON.stringify(document);
    var parameterValues = [documentJSON, bundleID];
    var soapMessage = buildSOAPMessage("addDocument", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param document [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.changeDocument = function(document, callbackHandler) {
    var parameterNames = ["document"];
    var documentJSON = JSON.stringify(document);
    var parameterValues = [documentJSON];
    var soapMessage = buildSOAPMessage("changeDocument", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param documentID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.removeDocument = function(documentID, callbackHandler) {
    var parameterNames = ["documentID"];
    var parameterValues = [documentID];
    var soapMessage = buildSOAPMessage("removeDocument", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param documentID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.getDocumentByID = function(documentID, callbackHandler) {
    var parameterNames = ["documentID"];
    var parameterValues = [documentID];
    var soapMessage = buildSOAPMessage("getDocumentByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param invoiceForApplication [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.addInvoiceForApplication = function(invoiceForApplication, callbackHandler) {
    var parameterNames = ["invoiceForApplication"];
    var invoiceForApplicationJSON = JSON.stringify(invoiceForApplication);
    var parameterValues = [invoiceForApplicationJSON];
    var soapMessage = buildSOAPMessage("addInvoiceForApplication", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param invoiceForApplication [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.changeInvoiceForApplication = function(invoiceForApplication, callbackHandler) {
    var parameterNames = ["invoiceForApplication"];
    var invoiceForApplicationJSON = JSON.stringify(invoiceForApplication);
    var parameterValues = [invoiceForApplicationJSON];
    var soapMessage = buildSOAPMessage("changeInvoiceForApplication", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param treasuryApplicationID [DEFAULT!!DEFAULT]
   * @param invoiceID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.getInvoiceForApplication = function(treasuryApplicationID, invoiceID, callbackHandler) {
    var parameterNames = ["treasuryApplicationID", "invoiceID"];
    var parameterValues = [treasuryApplicationID, invoiceID];
    var soapMessage = buildSOAPMessage("getInvoiceForApplication", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param invoiceBundle [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.addBundle = function(invoiceBundle, callbackHandler) {
    var parameterNames = ["invoiceBundle"];
    var invoiceBundleJSON = JSON.stringify(invoiceBundle);
    var parameterValues = [invoiceBundleJSON];
    var soapMessage = buildSOAPMessage("addBundle", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param invoiceBundle [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.changeBundle = function(invoiceBundle, callbackHandler) {
    var parameterNames = ["invoiceBundle"];
    var invoiceBundleJSON = JSON.stringify(invoiceBundle);
    var parameterValues = [invoiceBundleJSON];
    var soapMessage = buildSOAPMessage("changeBundle", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param invoiceBundleID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.removeBundle = function(invoiceBundleID, callbackHandler) {
    var parameterNames = ["invoiceBundleID"];
    var parameterValues = [invoiceBundleID];
    var soapMessage = buildSOAPMessage("removeBundle", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param invoiceBundleID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.getBundle = function(invoiceBundleID, callbackHandler) {
    var parameterNames = ["invoiceBundleID"];
    var parameterValues = [invoiceBundleID];
    var soapMessage = buildSOAPMessage("getBundle", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param invoiceList [DEFAULT!!DEFAULT]
   * @param responsibilityCenterID [DEFAULT!!DEFAULT]
   * @param targetResponsibilityCenterID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.tagInvoiceListWithResponsibilityCenter = function(invoiceList, responsibilityCenterID, targetResponsibilityCenterID, callbackHandler) {
    var parameterNames = ["invoiceList", "responsibilityCenterID", "targetResponsibilityCenterID"];
    var invoiceListJSON = JSON.stringify(invoiceList);
    var parameterValues = [invoiceListJSON, responsibilityCenterID, targetResponsibilityCenterID];
    var soapMessage = buildSOAPMessage("tagInvoiceListWithResponsibilityCenter", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param documentID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.shareDocument = function(documentID, callbackHandler) {
    var parameterNames = ["documentID"];
    var parameterValues = [documentID];
    var soapMessage = buildSOAPMessage("shareDocument", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param invoiceID [DEFAULT!!DEFAULT]
   * @param from [DEFAULT!!DEFAULT]
   * @param to [DEFAULT!!DEFAULT]
   * @param mailMessage [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.sendInvoiceEMail = function(invoiceID, from, to, mailMessage, callbackHandler) {
    var parameterNames = ["invoiceID", "from", "to", "mailMessage"];
    var parameterValues = [invoiceID, from, to, mailMessage];
    var soapMessage = buildSOAPMessage("sendInvoiceEMail", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param filter [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.getDocumentList = function(filter, callbackHandler) {
    var parameterNames = ["filter"];
    var filterJSON = JSON.stringify(filter);
    var parameterValues = [filterJSON];
    var soapMessage = buildSOAPMessage("getDocumentList", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param documentGUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.importDocument = function(documentGUID, callbackHandler) {
    var parameterNames = ["documentGUID"];
    var parameterValues = [documentGUID];
    var soapMessage = buildSOAPMessage("importDocument", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param invoiceID [DEFAULT!!DEFAULT]
   * @param paymentID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.linkPaymentToInvoice = function(invoiceID, paymentID, callbackHandler) {
    var parameterNames = ["invoiceID", "paymentID"];
    var parameterValues = [invoiceID, paymentID];
    var soapMessage = buildSOAPMessage("linkPaymentToInvoice", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param invoice [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.addInvoicePaymentDraft = function(invoice, callbackHandler) {
    var parameterNames = ["invoice"];
    var invoiceJSON = JSON.stringify(invoice);
    var parameterValues = [invoiceJSON];
    var soapMessage = buildSOAPMessage("addInvoicePaymentDraft", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param invoiceUID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.getDocumentByUID = function(invoiceUID, callbackHandler) {
    var parameterNames = ["invoiceUID"];
    var parameterValues = [invoiceUID];
    var soapMessage = buildSOAPMessage("getDocumentByUID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param invoiceNumber [DEFAULT!!DEFAULT]
   * @param documentTypeCode [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.getDocumentByNumber = function(invoiceNumber, documentTypeCode, callbackHandler) {
    var parameterNames = ["invoiceNumber", "documentTypeCode"];
    var parameterValues = [invoiceNumber, documentTypeCode];
    var soapMessage = buildSOAPMessage("getDocumentByNumber", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param documentTypeID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.getLastIssuedDocument = function(documentTypeID, callbackHandler) {
    var parameterNames = ["documentTypeID"];
    var parameterValues = [documentTypeID];
    var soapMessage = buildSOAPMessage("getLastIssuedDocument", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param notifications [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.notifyInvoiceListChanged = function(notifications, callbackHandler) {
    var parameterNames = ["notifications"];
    var notificationsJSON = JSON.stringify(notifications);
    var parameterValues = [notificationsJSON];
    var soapMessage = buildSOAPMessage("notifyInvoiceListChanged", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param invoiceID [DEFAULT!!DEFAULT]
   * @param paymentID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.removeInvoicePaymentLink = function(invoiceID, paymentID, callbackHandler) {
    var parameterNames = ["invoiceID", "paymentID"];
    var parameterValues = [invoiceID, paymentID];
    var soapMessage = buildSOAPMessage("removeInvoicePaymentLink", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param invoiceID [DEFAULT!!DEFAULT]
   * @param from [DEFAULT!!DEFAULT]
   * @param to [DEFAULT!!DEFAULT]
   * @param mailMessage [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.revokeDocument = function(invoiceID, from, to, mailMessage, callbackHandler) {
    var parameterNames = ["invoiceID", "from", "to", "mailMessage"];
    var parameterValues = [invoiceID, from, to, mailMessage];
    var soapMessage = buildSOAPMessage("revokeDocument", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param invoiceID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.checkInvoiceCanBeModified = function(invoiceID, callbackHandler) {
    var parameterNames = ["invoiceID"];
    var parameterValues = [invoiceID];
    var soapMessage = buildSOAPMessage("checkInvoiceCanBeModified", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param invoiceID [DEFAULT!!DEFAULT]
   * @param employeeID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.setResponsibleEmployee = function(invoiceID, employeeID, callbackHandler) {
    var parameterNames = ["invoiceID", "employeeID"];
    var parameterValues = [invoiceID, employeeID];
    var soapMessage = buildSOAPMessage("setResponsibleEmployee", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.getStatistics = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getStatistics", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param tag [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.getInvoicesByTag = function(tag, callbackHandler) {
    var parameterNames = ["tag"];
    var parameterValues = [tag];
    var soapMessage = buildSOAPMessage("getInvoicesByTag", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param documentTypeID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.getNextDocumentNumber = function(documentTypeID, callbackHandler) {
    var parameterNames = ["documentTypeID"];
    var parameterValues = [documentTypeID];
    var soapMessage = buildSOAPMessage("getNextDocumentNumber", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!Количество неотозванных счетов по собственной организации!DEFAULT]
   * @param tenantLegalEntityID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.getInvoicesCount = function(tenantLegalEntityID, callbackHandler) {
    var parameterNames = ["tenantLegalEntityID"];
    var parameterValues = [tenantLegalEntityID];
    var soapMessage = buildSOAPMessage("getInvoicesCount", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param invoiceID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.getInvoiceNotificationHistory = function(invoiceID, callbackHandler) {
    var parameterNames = ["invoiceID"];
    var parameterValues = [invoiceID];
    var soapMessage = buildSOAPMessage("getInvoiceNotificationHistory", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param base64ImageContent [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentServiceClient.prototype.parseInvoiceImageToText = function(base64ImageContent, callbackHandler) {
    var parameterNames = ["base64ImageContent"];
    var parameterValues = [base64ImageContent];
    var soapMessage = buildSOAPMessage("parseInvoiceImageToText", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * Client API to FinancialDocumentTemplateServiceWS web service
   */
  w.FinancialDocumentTemplateServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "FinancialDocumentTemplateServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param template [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentTemplateServiceClient.prototype.addTemplate = function(template, callbackHandler) {
    var parameterNames = ["template"];
    var templateJSON = JSON.stringify(template);
    var parameterValues = [templateJSON];
    var soapMessage = buildSOAPMessage("addTemplate", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param template [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentTemplateServiceClient.prototype.updateTemplate = function(template, callbackHandler) {
    var parameterNames = ["template"];
    var templateJSON = JSON.stringify(template);
    var parameterValues = [templateJSON];
    var soapMessage = buildSOAPMessage("updateTemplate", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param templateID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentTemplateServiceClient.prototype.removeTemplate = function(templateID, callbackHandler) {
    var parameterNames = ["templateID"];
    var parameterValues = [templateID];
    var soapMessage = buildSOAPMessage("removeTemplate", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param templateID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentTemplateServiceClient.prototype.getTemplateByID = function(templateID, callbackHandler) {
    var parameterNames = ["templateID"];
    var parameterValues = [templateID];
    var soapMessage = buildSOAPMessage("getTemplateByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentTemplateServiceClient.prototype.getTemplates = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getTemplates", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param templateVersionID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentTemplateServiceClient.prototype.getTemplateByVersionID = function(templateVersionID, callbackHandler) {
    var parameterNames = ["templateVersionID"];
    var parameterValues = [templateVersionID];
    var soapMessage = buildSOAPMessage("getTemplateByVersionID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param versionIDList [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentTemplateServiceClient.prototype.getTemplatesByVersionIDList = function(versionIDList, callbackHandler) {
    var parameterNames = ["versionIDList"];
    var versionIDListJSON = JSON.stringify(versionIDList);
    var parameterValues = [versionIDListJSON];
    var soapMessage = buildSOAPMessage("getTemplatesByVersionIDList", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentTemplateServiceClient.prototype.getDocumentTypes = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getDocumentTypes", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param templateBody [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.FinancialDocumentTemplateServiceClient.prototype.extractUserFieldsFromTemplate = function(templateBody, callbackHandler) {
    var parameterNames = ["templateBody"];
    var parameterValues = [templateBody];
    var soapMessage = buildSOAPMessage("extractUserFieldsFromTemplate", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * Client API to SalesServiceWS web service
   */
  w.SalesServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "SalesServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param pipeLine [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.SalesServiceClient.prototype.addPipeLine = function(pipeLine, callbackHandler) {
    var parameterNames = ["pipeLine"];
    var pipeLineJSON = JSON.stringify(pipeLine);
    var parameterValues = [pipeLineJSON];
    var soapMessage = buildSOAPMessage("addPipeLine", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param pipeLine [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.SalesServiceClient.prototype.updatePipeLine = function(pipeLine, callbackHandler) {
    var parameterNames = ["pipeLine"];
    var pipeLineJSON = JSON.stringify(pipeLine);
    var parameterValues = [pipeLineJSON];
    var soapMessage = buildSOAPMessage("updatePipeLine", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param pipeLineID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.SalesServiceClient.prototype.getPipeLineByID = function(pipeLineID, callbackHandler) {
    var parameterNames = ["pipeLineID"];
    var parameterValues = [pipeLineID];
    var soapMessage = buildSOAPMessage("getPipeLineByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.SalesServiceClient.prototype.getPipeLines = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getPipeLines", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param pipeLineID [DEFAULT!!DEFAULT]
   * @param pipeLineStage [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.SalesServiceClient.prototype.addPipeLineStage = function(pipeLineID, pipeLineStage, callbackHandler) {
    var parameterNames = ["pipeLineID", "pipeLineStage"];
    var pipeLineStageJSON = JSON.stringify(pipeLineStage);
    var parameterValues = [pipeLineID, pipeLineStageJSON];
    var soapMessage = buildSOAPMessage("addPipeLineStage", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param pipeLineStage [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.SalesServiceClient.prototype.updatePipeLineStage = function(pipeLineStage, callbackHandler) {
    var parameterNames = ["pipeLineStage"];
    var pipeLineStageJSON = JSON.stringify(pipeLineStage);
    var parameterValues = [pipeLineStageJSON];
    var soapMessage = buildSOAPMessage("updatePipeLineStage", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param pipeLineID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.SalesServiceClient.prototype.removePipeLine = function(pipeLineID, callbackHandler) {
    var parameterNames = ["pipeLineID"];
    var parameterValues = [pipeLineID];
    var soapMessage = buildSOAPMessage("removePipeLine", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param pipeLineStageID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.SalesServiceClient.prototype.removePipeLineStage = function(pipeLineStageID, callbackHandler) {
    var parameterNames = ["pipeLineStageID"];
    var parameterValues = [pipeLineStageID];
    var soapMessage = buildSOAPMessage("removePipeLineStage", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  
  /***
   * Package security rights constants
   */
  
  w.InvoiceSecurityRights = {
  
      INVOICE_PAYMENT_LINK_EDIT_RIGHT:new SecurityRightCheckHandle('4dfc73ad-48cd-4dd8-911f-52642d313bbe'),
      SET_RESPONSIBLE_EMPLOYEE_RIGHT:new SecurityRightCheckHandle('c11f69d0-e967-48b7-b39d-5f92c1a628bd'),
      X_DOCUMENT_TEMPLATES__CHANGE_DOCUMENT_TEMPLATES:new SecurityRightCheckHandle('c9d79f0c-ad9f-469d-9ae7-fac6575649bc'),
      X_DOCUMENT_TEMPLATES__VIEW_DOCUMENT_TEMPLATES:new SecurityRightCheckHandle('3dfd377b-1856-40ab-8988-13f70f0a5b45'),
      X_INVOICES__CHANGE_LINKED_PAYMENTS:new SecurityRightCheckHandle('b90c7034-01a2-45d4-803e-8a4d8b4861d5'),
      X_INVOICES__CHANGE_OUTGOING_INVOICES:new SecurityRightCheckHandle('8bb6fb9b-5fd2-476b-8fed-d28088b88ce7'),
      X_INVOICES__CHANGE_RESPONSIBLE_EMPLOYEE:new SecurityRightCheckHandle('7e063ae5-9595-492b-a955-8229cb6b3999'),
      X_INVOICES__VIEW_INCOMING_INVOICES:new SecurityRightCheckHandle('a3f32196-696f-4b24-8992-d0f1d5ba1c77'),
      X_INVOICES__VIEW_OUTGOING_INVOICES:new SecurityRightCheckHandle('ef972562-29d3-478f-827e-74ba3564a33f'),
      X_TRANSACTIONS__CHANGE_TRANSACTIONS:new SecurityRightCheckHandle('fcb6cd26-9665-4c4d-8f5f-ae33662ac5d7'),
      X_TRANSACTIONS__VIEW_TRANSACTIONS:new SecurityRightCheckHandle('8dff6b67-5511-4517-9380-1191a9590c0d')
  };
  })(typeof exports !== 'undefined' ? exports : window);
  