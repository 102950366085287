import { compose } from 'redux';

import { IAppState } from '@store';
import { createAsyncSelector, createRightCheck, createSelector } from '@flux';
import { DataSourceItem } from '@core/api/core';
import { sortDescBy } from '@utils';
import { createObjectMap } from '@utils/object';
import { TransactionOperationType, TransactionOperationTypeName, TransactionSlice } from '@transactions/model';

const selectTransactionsRight = createRightCheck({
	component: 'Sales',
	view: 'xTransactions_ViewTransactions',
	change: 'xTransactions_ChangeTransactions',
});

function selectDateRange(state: IAppState): DateRange {
	return state.transactions.main.dateRange;
}

const selectAsyncTurnovers = createAsyncSelector<Array<PosTerminalTransactionTurnover>, IAppState>({
	get: s => s.transactions.main.turnovers,
	selector: createSelector(
		s => s.transactions.main.turnovers.item,
		item => item,
	),
});

const selectAsyncTransactions = createAsyncSelector<Array<PosTerminalTransaction>, IAppState>({
	get: s => s.transactions.main.transactions,
	selector: createSelector(
		s => s.transactions.main.transactions.item,
		item => item,
	),
});

const selectAsyncTerminals = createAsyncSelector<Array<PosTerminal>, IAppState>({
	get: s => s.transactions.main.terminals,
	selector: createSelector(
		s => s.transactions.main.terminals.item,
		item => item,
	),
});

const selectTerminalsMap = createSelector(selectAsyncTerminals.selectItem, terminals => {
	const terminalsMap = createObjectMap(terminals, x => x.ID);

	return terminalsMap;
});

const selectTurnoversResultsMap = createSelector(selectAsyncTurnovers.selectItem, turnovers => {
	const map: Record<
		string,
		{
			turnover: number;
			commission: number;
			enrolled: number;
		}
	> = {};

	for (const turnover of turnovers) {
		if (!map[turnover.TransactionDate]) {
			map[turnover.TransactionDate] = {
				turnover: 0,
				commission: 0,
				enrolled: 0,
			};
		}

		const result = map[turnover.TransactionDate];

		result.turnover += turnover.IncomeAmount + turnover.FailedAmount;
		result.commission += turnover.CommissionAmount;
		result.enrolled += turnover.IncomeAmount;
	}

	return map;
});

const selectFilteredTurnovers = createSelector(
	selectAsyncTurnovers.selectItem,
	selectTerminalsFilter,
	(turnovers, terminalsFilter) => {
		const filteredTurnovers = compose((turnovers: Array<PosTerminalTransactionTurnover>) =>
			terminalsFilter.length > 0 ? turnovers.filter(x => terminalsFilter.includes(x.TerminalUID)) : turnovers,
		)(turnovers);

		return filteredTurnovers;
	},
);

const selectTransactionsStatistics = createSelector(selectFilteredTurnovers, turnovers => {
	let income = 0;
	let turnoverTotal = 0;
	let commission = 0;
	let failed = 0;
	let chargeback = 0;
	let revenue = 0;

	for (const turnover of turnovers) {
		income += turnover.IncomeAmount;
		turnoverTotal += turnover.IncomeAmount + turnover.FailedAmount;
		commission += turnover.CommissionAmount;
		failed += turnover.FailedAmount;
		chargeback += turnover.ChargebackAmount;
	}

	revenue = income - commission - chargeback;

	return {
		[TransactionSlice.TURNOVER]: turnoverTotal,
		[TransactionSlice.FAILED]: failed,
		[TransactionSlice.COMMISION]: commission,
		[TransactionSlice.CHARGEBACK]: chargeback,
		[TransactionSlice.REVENUE]: revenue,
	};
});

const selectFilteredTransactions = createSelector(
	selectAsyncTransactions.selectItem,
	selectOperationTypesFilter,
	selectTerminalsFilter,
	(transactions, operationTypesFilter, terminalsFilter) => {
		return compose(
			(transactions: Array<PosTerminalTransaction>) =>
				sortDescBy(transactions, [{ fn: item => item.TransactionDate, isDate: true }, { fn: item => item.ID }]),
			(transactions: Array<PosTerminalTransaction>) =>
				terminalsFilter.length > 0 ? transactions.filter(x => terminalsFilter.includes(x.TerminalUID)) : transactions,
			(transactions: Array<PosTerminalTransaction>) =>
				operationTypesFilter.length > 0
					? transactions.filter(x => operationTypesFilter.includes(x.OperationType))
					: transactions,
		)(transactions);
	},
);

function selectOperationTypesFilter(state: IAppState) {
	return state.transactions.main.operationTypesFilter;
}

function selectTerminalsFilter(state: IAppState) {
	return state.transactions.main.terminalsFilter;
}

const selectOperationTypesDataSource = createSelector(
	() => {},
	() => {
		return [
			new DataSourceItem({ value: TransactionOperationType.INCOME, text: TransactionOperationTypeName.INCOME }),
			new DataSourceItem({ value: TransactionOperationType.CHARGEBACK, text: TransactionOperationTypeName.CHARGEBACK }),
		];
	},
);

const selectIsDataFetching = (state: IAppState) =>
	mainTransactionsSelectorsPack.selectAsyncTransactions.selectIsFetching(state) ||
	mainTransactionsSelectorsPack.selectAsyncTurnovers.selectIsFetching(state);

const selectIsDataLoaded = (state: IAppState) =>
	mainTransactionsSelectorsPack.selectAsyncTransactions.selectIsLoaded(state) &&
	mainTransactionsSelectorsPack.selectAsyncTurnovers.selectIsLoaded(state);

export const mainTransactionsSelectorsPack = {
	selectTransactionsRight,
	selectDateRange,
	selectAsyncTransactions,
	selectAsyncTerminals,
	selectAsyncTurnovers,
	selectTerminalsMap,
	selectTurnoversResultsMap,
	selectTransactionsStatistics,
	selectFilteredTransactions,
	selectOperationTypesFilter,
	selectTerminalsFilter,
	selectOperationTypesDataSource,
	selectIsDataFetching,
	selectIsDataLoaded,
};

export { selectTransactionsRight };
