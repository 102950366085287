import React, { forwardRef } from 'react';

import { detectIsFunction } from '@utils/helpers';
import withConfirm, { ConfirmEnhancerProps } from '@core/hocs/with-confirm.hoc';
import { Ripple } from '@ui/ripple';
import { OkIcon } from '@ui/icons/ok';
import { Root, ContentLayout } from './styled';

export type IconButtonProps = {
	variant?: 'square' | 'rounded';
	shadowed?: boolean;
	stopPropagation?: boolean;
	children: React.ReactNode;
	isSilentHover?: boolean;
	isSilentDisabled?: boolean;
	size?: number;
} & React.AllHTMLAttributes<HTMLButtonElement>;

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
	const {
		variant,
		shadowed,
		disabled,
		stopPropagation,
		isSilentHover,
		isSilentDisabled,
		children,
		size,
		onClick,
		...rest
	} = props;

	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		stopPropagation && e.stopPropagation();
		detectIsFunction(onClick) && setTimeout(() => onClick(e), 100);
	};

	return (
		<Root
			ref={ref}
			variant={variant}
			shadowed={shadowed}
			disabled={isSilentDisabled || disabled}
			isSilentHover={isSilentHover}
			isSilentDisabled={isSilentDisabled}
			onClick={handleClick}
			{...(rest as any)}>
			<Ripple skip={isSilentDisabled || disabled}>
				<ContentLayout size={size}>{children}</ContentLayout>
			</Ripple>
		</Root>
	);
});

IconButton.defaultProps = {
	size: 32,
};

const EnhancedIconButton = withConfirm<IconButtonProps>()(IconButton);

export type IconButtonConfirmProps = IconButtonProps & ConfirmEnhancerProps;

const IconButtonConfirm: React.FC<IconButtonConfirmProps> = props => {
	return <EnhancedIconButton {...props} renderConfirmContent={() => <OkIcon color='alarm' />} />;
};

export { IconButton, IconButtonConfirm };
