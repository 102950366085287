import {
	StoreAsyncItem,
	checkAsyncAction,
	createAsyncInitialState,
	createReducer,
	invalidateStateFromAction,
	optimisticUpdateState,
	restoreValidationState,
} from '@flux';

import { ComplexFilter } from '@core/api/core';
import { types } from '@invoices/actions/types';
import { InvoiceSegmentCode } from '@invoices/models';
import { createDefaultPeriod } from '@utils/date';

export type MainInvoicesState = {
	autoinsert: Partial<Invoice> | null;
	clientsFilter: Record<string, boolean>;
	dateRange: DateRange;
	documentsFilter: Array<string>;
	hasSingleCompany: StoreAsyncItem<boolean>;
	invoiceNotificationHistory: StoreAsyncItem<Array<InvoiceNotificationEvent>>;
	invoices: StoreAsyncItem<Array<Invoice>>;
	isContactsEnabled: StoreAsyncItem<boolean>;
	isProductsEnabled: StoreAsyncItem<boolean>;
	lastInvoice: StoreAsyncItem<Invoice>;
	lastSignEmployeeChoice: StoreAsyncItem<Record<string, number>>;
	managersFilter: Record<string, boolean>;
	segmentFilter: ComplexFilter<Record<InvoiceSegmentCode, boolean>>;
	tenantEntitiesFilter: Record<string, boolean>;
	textFilter: string;
};

const initialState: MainInvoicesState = {
	autoinsert: null,
	clientsFilter: {},
	dateRange: createDefaultPeriod('month'),
	documentsFilter: [],
	hasSingleCompany: createAsyncInitialState(false),
	invoiceNotificationHistory: createAsyncInitialState([]),
	invoices: createAsyncInitialState([]),
	isContactsEnabled: createAsyncInitialState(true),
	isProductsEnabled: createAsyncInitialState(true),
	lastInvoice: createAsyncInitialState(null),
	lastSignEmployeeChoice: createAsyncInitialState(null),
	managersFilter: {},
	segmentFilter: new ComplexFilter({
		value: {
			[InvoiceSegmentCode.ISSUED]: true,
			[InvoiceSegmentCode.UNPAID]: false,
			[InvoiceSegmentCode.PAYED]: false,
			[InvoiceSegmentCode.OVERDUE]: false,
			[InvoiceSegmentCode.DRAFT]: false,
		},
	}),
	tenantEntitiesFilter: {},
	textFilter: '',
};

const mainInvoicesReducer = createReducer<MainInvoicesState>(initialState, {
	[types.ADD_INVOICE]: (action: AsyncAction<Invoice>, state) => {
		return {
			invoices: invalidateStateFromAction(action, state.invoices),
			lastInvoice: createAsyncInitialState(null),
		};
	},
	[types.AUTOINSERT_TO_NEW_INVOICE]: (action: StaticAction<Partial<Invoice>>) => {
		return {
			autoinsert: action.value,
		};
	},
	[types.CLEAR_INVOICE_NOTIFICATION_HISTORY]: () => {
		return {
			invoiceNotificationHistory: createAsyncInitialState([]),
		};
	},
	[types.FETCH_HAS_SINGLE_COMPANY]: (action: AsyncAction<boolean>, state) => {
		return {
			hasSingleCompany: checkAsyncAction(action, state.hasSingleCompany),
		};
	},
	[types.FETCH_INVOICE_NOTIFICATION_HISTORY]: (action: AsyncAction<Array<InvoiceNotificationEvent>>, state) => {
		return {
			invoiceNotificationHistory: checkAsyncAction(action, state.invoices),
		};
	},
	[types.FETCH_INVOICES]: (action: AsyncAction<Array<Invoice>>, state) => {
		return {
			invoices: checkAsyncAction(action, state.invoices),
		};
	},
	[types.FETCH_IS_CONTACTS_ENABLED]: (action: AsyncAction<boolean>, state) => {
		return {
			isContactsEnabled: checkAsyncAction(action, state.isContactsEnabled),
		};
	},
	[types.FETCH_IS_PRODUCTS_ENABLED]: (action: AsyncAction<boolean>, state) => {
		return {
			isProductsEnabled: checkAsyncAction(action, state.isProductsEnabled),
		};
	},
	[types.FETCH_LAST_INVOICE]: (action: AsyncAction<Invoice>, state) => {
		return {
			lastInvoice: checkAsyncAction(action, state.lastInvoice),
		};
	},
	[types.FETCH_LAST_SIGN_EMPLOYEES_CHOICE]: (action: AsyncAction<Record<string, number> | null>, state) => {
		return {
			lastSignEmployeeChoice: checkAsyncAction(action, state.lastSignEmployeeChoice),
		};
	},
	[types.INVALIDATE_INVOICES]: (action: StaticAction, state) => {
		return {
			invoices: invalidateStateFromAction(action, state.invoices),
		};
	},
	[types.LINK_PAYMENTS_TO_INVOICE]: (action: AsyncAction<boolean>, state) => {
		return {
			invoices: invalidateStateFromAction(action, state.invoices),
		};
	},
	[types.REMOVE_DRAFT]: (action: AsyncAction<boolean>, state) => {
		return {
			invoices: invalidateStateFromAction(action, state.invoices),
		};
	},
	[types.RESET_FILTERS]: () => {
		return {
			segmentFilter: new ComplexFilter({
				value: {
					[InvoiceSegmentCode.ISSUED]: true,
					[InvoiceSegmentCode.UNPAID]: false,
					[InvoiceSegmentCode.PAYED]: false,
					[InvoiceSegmentCode.OVERDUE]: false,
					[InvoiceSegmentCode.DRAFT]: false,
				},
			}),
			textFilter: '',
			clientsFilter: {},
			tenantEntitiesFilter: {},
			managersFilter: {},
			documentsFilter: [],
		};
	},
	[types.REVOKE_INVOICE]: (action: AsyncAction<boolean>, state) => {
		return {
			invoices: invalidateStateFromAction(action, state.invoices),
		};
	},
	[types.SET_DATE_RANGE]: (action: StaticAction<DateRange>, state) => {
		return {
			dateRange: action.value,
			invoices: invalidateStateFromAction(action, state.invoices),
		};
	},
	[types.SET_FILTER_BY_CLIENTS]: (action: StaticAction<Record<string, boolean>>) => {
		return {
			clientsFilter: { ...action.value },
		};
	},
	[types.SET_FILTER_BY_DOCUMENTS]: (action: StaticAction<Array<string>>) => {
		return {
			documentsFilter: [...action.value],
		};
	},
	[types.SET_FILTER_BY_MANAGERS]: (action: StaticAction<Record<string, boolean>>) => {
		return {
			managersFilter: { ...action.value },
		};
	},
	[types.SET_FILTER_BY_SEGMENT]: (action: StaticAction<ComplexFilter<Record<InvoiceSegmentCode, boolean>>>) => {
		return {
			segmentFilter: action.value,
		};
	},
	[types.SET_FILTER_BY_TENANT_ENTITIES]: (action: StaticAction<Record<string, boolean>>) => {
		return {
			tenantEntitiesFilter: { ...action.value },
		};
	},
	[types.SET_FILTER_BY_TEXT]: (action: StaticAction<string>) => {
		return {
			textFilter: action.value,
		};
	},
	[types.SET_IS_CONTACTS_ENABLED]: (action: AsyncAction<boolean>, state) => {
		return {
			isContactsEnabled: optimisticUpdateState({
				action,
				state: state.isContactsEnabled,
				getOptimisticValue: x => !x.item,
			}),
		};
	},
	[types.SET_IS_PRODUCTS_ENABLED]: (action: AsyncAction<boolean>, state) => {
		return {
			isProductsEnabled: optimisticUpdateState({
				action,
				state: state.isProductsEnabled,
				getOptimisticValue: x => !x.item,
			}),
		};
	},
	[types.SET_LAST_SIGN_EMPLOYEES_CHOICE]: (action: AsyncAction<Record<string, number> | null>, state) => {
		return {
			lastSignEmployeeChoice: invalidateStateFromAction(action, state.lastSignEmployeeChoice),
		};
	},
	[types.SET_RESPONSIBLE_EMPLOYEE]: (action: AsyncAction<boolean>, state) => {
		return {
			invoices: invalidateStateFromAction(action, state.invoices),
		};
	},
	[types.SHARE_INVOICE]: (action: AsyncAction<Invoice>, state) => {
		return {
			invoices: invalidateStateFromAction(action, state.invoices),
		};
	},
	[types.SYNC_PAYMENTS_WITH_INVOICES]: (action: AsyncAction<Array<number>>, state) => {
		return {
			invoices: invalidateStateFromAction(action, state.invoices),
		};
	},
	[types.UPDATE_INVOICE]: (action: AsyncAction<Invoice>, state) => {
		return {
			invoices: invalidateStateFromAction(action, state.invoices),
			lastInvoice: createAsyncInitialState(null),
		};
	},
});

export default mainInvoicesReducer;
