import { createDefaultPeriod } from '@utils/date';
import {
	StoreAsyncItem,
	createReducer,
	createAsyncInitialState,
	checkAsyncAction,
	invalidateStateFromAction,
} from '@flux';
import { mainTransactionsActionTypes } from '@transactions/actions';


export type MainTransactionsState = {
	dateRange: DateRange;
	turnovers: StoreAsyncItem<Array<PosTerminalTransactionTurnover>>;
	transactions: StoreAsyncItem<Array<PosTerminalTransaction>>;
	terminals: StoreAsyncItem<Array<PosTerminal>>;
	operationTypesFilter: Array<string>;
	terminalsFilter: Array<string>;
};

const initialState: MainTransactionsState = {
	dateRange: createDefaultPeriod('month'),
	turnovers: createAsyncInitialState([]),
	transactions: createAsyncInitialState([]),
	terminals: createAsyncInitialState([]),
	operationTypesFilter: [],
	terminalsFilter: [],
};

const mainTransactionsReducer = createReducer<MainTransactionsState>(initialState, {
	[mainTransactionsActionTypes.SET_DATE_RANGE]: (action: StaticAction<DateRange>, state) => {
		return {
			dateRange: action.value,
			turnovers: invalidateStateFromAction(action, state.turnovers),
			transactions: invalidateStateFromAction(action, state.transactions),
		};
	},
	[mainTransactionsActionTypes.SET_FILTER_BY_OPERATION_TYPES]: (action: StaticAction<Array<string>>) => {
		return {
			operationTypesFilter: action.value,
		};
	},
	[mainTransactionsActionTypes.SET_FILTER_BY_TERMINALS]: (action: StaticAction<Array<string>>) => {
		return {
			terminalsFilter: action.value,
		};
	},
	[mainTransactionsActionTypes.RESET_FILTERS]: () => {
		return {
			operationTypesFilter: [],
			terminalsFilter: [],
		};
	},
	[mainTransactionsActionTypes.FETCH_TURNOVERS]: (action: AsyncAction<Array<PosTerminalTransactionTurnover>>, state) => {
		return {
			turnovers: checkAsyncAction(action, state.turnovers),
		};
	},
	[mainTransactionsActionTypes.FETCH_TRANSACTIONS]: (action: AsyncAction<Array<PosTerminalTransaction>>, state) => {
		return {
			transactions: checkAsyncAction(action, state.transactions),
		};
	},
	[mainTransactionsActionTypes.FETCH_TERMINALS]: (action: AsyncAction<Array<PosTerminal>>, state) => {
		return {
			terminals: checkAsyncAction(action, state.terminals),
		};
	},
	[mainTransactionsActionTypes.IMPORT_TERMINALS]: (action: AsyncAction, state) => {
		return {
			terminals: invalidateStateFromAction(action, state.terminals),
		};
	},
	[mainTransactionsActionTypes.IMPORT_TRANSACTIONS]: (action: AsyncAction, state) => {
		return {
			transactions: invalidateStateFromAction(action, state.transactions),
			turnovers: invalidateStateFromAction(action, state.turnovers),
		};
	},
});

export default mainTransactionsReducer;
