import React, { useState } from 'react';

import { detectIsFunction } from '@utils/helpers';
import { IconMenu } from '@ui/icon-menu';
import { RaisedButton } from '@ui/raised-button';
import { DropdownIcon } from '@ui/icons/dropdown';
import { Label, Separator, ActionMenuItemRoot, ActionMenuItemIconLayout, ActionMenuItemContentLayout } from './styled';

export type ActionMenuProps = {
	label?: string;
	minWidth?: string;
	disabled?: boolean;
	renderTrigger?: (options: RenderTriggerOptions) => React.ReactNode;
	children: React.ReactNode;
	onClick?: React.EventHandler<React.SyntheticEvent<{}>>;
};

const ActionMenu: React.FC<ActionMenuProps> = props => {
	const { label, minWidth, disabled, children, renderTrigger } = props;
	const [isOpen, setIsOpen] = useState(false);

	const handleCloseMenu = () => setIsOpen(false);

	const handleToggleMenu = () => setIsOpen(!isOpen);

	const renderChildren = () => {
		return React.Children.map(children, (child: React.ReactElement<any>) => {
			return child
				? React.cloneElement(
						child,
						Object.assign(
							{},
							{
								handleCloseMenu,
								minWidth,
							},
						),
				  )
				: null;
		});
	};

	return (
		<IconMenu
			open={isOpen}
			iconButtonElement={
				detectIsFunction(renderTrigger) ? (
					(renderTrigger({ onToggle: handleToggleMenu }) as any)
				) : (
					<RaisedButton appearance='contained' color='primary' disabled={disabled} onClick={handleToggleMenu}>
						<Label>{label}</Label>
						<Separator>|</Separator>
						<DropdownIcon size={24} color='light' />
					</RaisedButton>
				)
			}
			anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			targetOrigin={{ horizontal: 'right', vertical: 'top' }}
			autoWidth={false}
			onRequestChange={handleCloseMenu}>
			{renderChildren()}
		</IconMenu>
	);
};

type ActionMenuItemProps = {
	label: React.ReactNode;
	icon?: React.ReactElement;
	iconMarginLeft?: string;
	minWidth?: string;
	disabled?: boolean;
	onClick?: (e?: any) => void;
	handleCloseMenu?: () => void;
};

const ActionMenuItem: React.FC<ActionMenuItemProps> = props => {
	const { label, icon, iconMarginLeft, minWidth, disabled, onClick, handleCloseMenu } = props;

	const handleClick = (e: React.SyntheticEvent) => {
		e.stopPropagation();

		if (disabled) return;

		onClick &&
			setTimeout(() => {
				onClick(e);
			});

		handleCloseMenu();
	};

	return (
		<ActionMenuItemRoot minWidth={minWidth} onClick={handleClick}>
			<ActionMenuItemContentLayout disabled={disabled}>
				<ActionMenuItemIconLayout marginLeft={iconMarginLeft}>{icon}</ActionMenuItemIconLayout>
				{label}
			</ActionMenuItemContentLayout>
		</ActionMenuItemRoot>
	);
};

export type RenderTriggerOptions = {
	onToggle: () => void;
};

export { ActionMenu, ActionMenuItem };
