import { Store, compose } from 'redux';

import { IAppState } from '@store';
import { getPropInSafe } from '@utils';
import { securitySelectorsPack } from '@platform/selectors/security.selector';
import { emitActionMessage } from '@shared/actions/action-message.actions';
import { mainTypes as operationsTypes } from '@pl/actions/types';
import { types as fundsRegistersTypes } from '@funds-registers/actions/types';
import { types as paymentsTypes } from '@payments/actions/types';
import { types as cashflowItemsTypes } from '@cashflow-items/actions/types';
import { types as cashflowLimitsTypes } from '@cashflow-limits/actions/types';
import { types as projectEmployeesTypes } from '@project-employees/actions/types';
import { REVOKE_TENANT_BILL, REGISTER_SERVICE_REQUEST } from '@platform/actions/billing.actions';
import { UPDATE_TENANT } from '@platform/actions/tenant-account.actions';
import { constants as configConstants } from '@platform/actions/configuration.actions';
import { types as projectManagementTypes } from '@project-management/actions/types';
import { types as invoicesTypes } from '@invoices/actions/types';
import { types as documentsTypes } from '@documents/actions/types';
import { types as integrationsTypes } from '@integrations/actions/types';
import { types as documentTemplatesTypes } from '@document-templates/actions/types';
import { types as businessUnitsTypes } from '@business-units/actions/types';
import { types as cashflowPaymentRulesTypes } from '@cashflow-payment-rules/actions/types';
import { types as projectPaymentRulesTypes } from '@project-payment-rules/actions/types';
import { types as productsTypes } from '@products/actions/types';
import { types as clientsTypes } from '@clients/actions/types';
import { types as counterpartiesTypes } from '@counterparties/actions/types';
import { types as tenantLegalEntitiesTypes } from '@tenant-legal-entities/actions/types';
import { types as employeesTypes } from '@employees/actions/types';
import { types as transactionsTypes } from '@transactions/actions';
import { types as transferRulesTypes } from '@transfer-rules/actions/types';

function askDemoBusinessCensor(store: Store<IAppState>, action: AsyncAction<unknown>) {
	return (pass: boolean): boolean => {
		if (!pass) return false;
		const { getState, dispatch } = store;
		const isDemoBusiness = getPropInSafe(getState(), s => securitySelectorsPack.selectIsDemoBusinessAccount(s), false);
		const emitMessage = () =>
			dispatch(emitActionMessage(`Извините, но вы не можете редактировать данные в демо-режиме 🙄`, 'warning'));

		if (!isDemoBusiness) return true;

		if (blackListMap[action.type]) {
			emitMessage();
			return false;
		}

		return true;
	};
}

function askReadonlyCensor(store: Store<IAppState>, action: AsyncAction<unknown>) {
	return (pass: boolean): boolean => {
		if (!pass) return false;
		const { getState, dispatch } = store;
		const canChangeData = getPropInSafe(getState(), s => !securitySelectorsPack.selectHasReadonlyRight(s), true);
		const emitMessage = () =>
			dispatch(emitActionMessage(`Извините, но ваша роль не предусматривает редактирование данных 🙄`, 'warning'));

		if (canChangeData) return true;

		if (blackListMap[action.type]) {
			emitMessage();
			return false;
		}

		return true;
	};
}

function createCensorInterceptorEffect() {
	return (store: Store<IAppState>) =>
		(next: (action: AsyncAction<unknown>) => void) =>
		(action: AsyncAction<unknown>) => {
			const pass = compose(askDemoBusinessCensor(store, action), askReadonlyCensor(store, action))(true);

			pass && next(action);
		};
}

const blackListMap = {
	[operationsTypes.ADD_OPERATION]: true,
	[operationsTypes.UPDATE_OPERATION]: true,
	[operationsTypes.REMOVE_OPERATIONS]: true,
	[operationsTypes.LINK_OPERATIONS_TO_CASHFLOW_ITEM]: true,
	[operationsTypes.MASS_UPDATE_OPERATIONS]: true,
	[operationsTypes.SEND_PLAN_OPERATIONS_TO_BANK]: true,
	[operationsTypes.ADD_OPERATIONS_BY_TEMPLATE]: true,
	[operationsTypes.UPDATE_OPERATIONS_BY_TEMPLATE]: true,
	[operationsTypes.SPLIT_OPERATION]: true,
	[operationsTypes.UPDATE_SPLIT_OPERATIONS]: true,
	[operationsTypes.TRANSFORM_PLAN_OPERATIONS_TO_FACT]: true,
	[paymentsTypes.UPDATE_PAYMENT]: true,
	[paymentsTypes.REMOVE_PAYMENT]: true,
	[paymentsTypes.MARK_AS_TRANSFER]: true,
	[paymentsTypes.UNMARK_TRANSFER]: true,
	[paymentsTypes.MERGE_PAYMENTS_TO_TRANSFER]: true,
	[cashflowItemsTypes.ADD_CASHFLOW_ITEM]: true,
	[cashflowItemsTypes.UPDATE_CASHFLOW_ITEM]: true,
	[cashflowItemsTypes.REMOVE_CASHFLOW_ITEM]: true,
	[cashflowPaymentRulesTypes.ADD_PAYMENT_RULE]: true,
	[cashflowPaymentRulesTypes.REMOVE_PAYMENT_RULE]: true,
	[cashflowPaymentRulesTypes.UPDATE_PAYMENT_RULE]: true,
	[cashflowPaymentRulesTypes.SET_PAYMENT_RULE_ACTIVITY]: true,
	[cashflowPaymentRulesTypes.EXECUTE_PAYMENT_RULES]: true,
	[fundsRegistersTypes.SET_FUNDS_REGISTER_ACCESS]: true,
	[fundsRegistersTypes.UPDATE_FUNDS_REGISTER]: true,
	[fundsRegistersTypes.REMOVE_FUNDS_REGISTER]: true,
	[fundsRegistersTypes.RECALCULATE_FUNDS_REGISTER_BALANCES]: true,
	[cashflowLimitsTypes.SET_CASHFLOW_LIMITS]: true,
	[projectManagementTypes.ADD_PROJECT]: true,
	[projectManagementTypes.UPDATE_PROJECT]: true,
	[projectManagementTypes.REMOVE_PROJECT]: true,
	[projectManagementTypes.LINK_OPERATIONS_TO_PROJECT]: true,
	[projectEmployeesTypes.ADD_EMPLOYEE]: true,
	[projectEmployeesTypes.UPDATE_EMPLOYEE]: true,
	[projectEmployeesTypes.REMOVE_EMPLOYEE]: true,
	[projectEmployeesTypes.SET_PROJECS_ACCESS]: true,
	[projectEmployeesTypes.ASSIGN_ROLE_TO_EMPLOYEES]: true,
	[projectPaymentRulesTypes.ADD_PAYMENT_RULE]: true,
	[projectPaymentRulesTypes.REMOVE_PAYMENT_RULE]: true,
	[projectPaymentRulesTypes.UPDATE_PAYMENT_RULE]: true,
	[projectPaymentRulesTypes.SET_PAYMENT_RULE_ACTIVITY]: true,
	[projectPaymentRulesTypes.EXECUTE_PAYMENT_RULES]: true,
	[transferRulesTypes.ADD]: true,
	[transferRulesTypes.REMOVE]: true,
	[transferRulesTypes.UPDATE]: true,
	[transferRulesTypes.SET_TRANSFER_RULE_ACTIVITY]: true,
	[transferRulesTypes.APPLY]: true,
	[invoicesTypes.ADD_INVOICE]: true,
	[invoicesTypes.UPDATE_INVOICE]: true,
	[invoicesTypes.SET_RESPONSIBLE_EMPLOYEE]: true,
	[invoicesTypes.REMOVE_DRAFT]: true,
	[invoicesTypes.REVOKE_INVOICE]: true,
	[invoicesTypes.SHARE_INVOICE]: true,
	[invoicesTypes.LINK_PAYMENTS_TO_INVOICE]: true,
	[invoicesTypes.SYNC_PAYMENTS_WITH_INVOICES]: true,
	[documentsTypes.ADD_DOCUMENT]: true,
	[documentsTypes.UPDATE_DOCUMENT]: true,
	[documentsTypes.REMOVE_DOCUMENT]: true,
	[REVOKE_TENANT_BILL]: true,
	[REGISTER_SERVICE_REQUEST]: true,
	[configConstants.UPDATE_TENANT_BUSINESS_SERVICE_SETTINGS]: true,
	[UPDATE_TENANT]: true,
	[integrationsTypes.ADD_EXTERNAL_SYSTEM_ACCOUNT]: true,
	[integrationsTypes.UPDATE_EXTERNAL_SYSTEM_ACCOUNT]: true,
	[integrationsTypes.REMOVE_EXTERNAL_SYSTEM_ACCOUNT]: true,
	[integrationsTypes.SET_LAST_ACCOUNT_STATEMENT_IMPORT]: true,
	[integrationsTypes.SET_LAST_IMPORTED_FILE_DATA]: true,
	[documentTemplatesTypes.ADD_DOCUMENT_TEMPLATE]: true,
	[documentTemplatesTypes.UPDATE_DOCUMENT_TEMPLATE]: true,
	[documentTemplatesTypes.REMOVE_DOCUMENT_TEMPLATE]: true,
	[businessUnitsTypes.ADD_BUSINESS_UNIT]: true,
	[businessUnitsTypes.UPDATE_BUSINESS_UNIT]: true,
	[businessUnitsTypes.REMOVE_BUSINESS_UNIT]: true,
	[businessUnitsTypes.LINK_OPERATIONS_TO_BUSINESS_UNIT]: true,
	[productsTypes.ADD_PRODUCT]: true,
	[productsTypes.UPDATE_PRODUCT]: true,
	[productsTypes.REMOVE_PRODUCT]: true,
	[productsTypes.ADD_PRODUCT_GROUP]: true,
	[productsTypes.UPDATE_PRODUCT_GROUP]: true,
	[productsTypes.REMOVE_PRODUCT_GROUP]: true,
	[productsTypes.LINK_PRODUCTS_TO_PRODUCT_GROUP]: true,
	[clientsTypes.ADD_CLIENT]: true,
	[clientsTypes.REMOVE_CLIENT]: true,
	[clientsTypes.UPDATE_CLIENT]: true,
	[counterpartiesTypes.ADD_COUNTERPARTY]: true,
	[counterpartiesTypes.UPDATE_COUNTERPARTY]: true,
	[counterpartiesTypes.REMOVE_COUNTERPARTY]: true,
	[counterpartiesTypes.ADD_COUNTERPARTY_GROUP]: true,
	[counterpartiesTypes.UPDATE_COUNTERPARTY_GROUP]: true,
	[counterpartiesTypes.REMOVE_COUNTERPARTY_GROUP]: true,
	[counterpartiesTypes.LINK_GROUPS_TO_COUNTERPARTY]: true,
	[counterpartiesTypes.LINK_MANAGER_TO_COUNTERPARTY]: true,
	[counterpartiesTypes.TRANSFORM_COUNTERPARTY_TO_TENANT_LEGAL_ENTITY]: true,
	[tenantLegalEntitiesTypes.ADD_TENANT_LEGAL_ENTITY]: true,
	[tenantLegalEntitiesTypes.UPDATE_TENANT_LEGAL_ENTITY]: true,
	[tenantLegalEntitiesTypes.REMOVE_TENANT_LEGAL_ENTITY]: true,
	[tenantLegalEntitiesTypes.TRANSFORM_TENANT_LEGAL_ENTITY_TO_COUNTERPARTY]: true,
	[employeesTypes.ADD_EMPLOYEE]: true,
	[employeesTypes.UPDATE_EMPLOYEE]: true,
	[employeesTypes.REMOVE_EMPLOYEE]: true,
	[employeesTypes.ADD_USER]: true,
	[employeesTypes.UPDATE_USER]: true,
	[transactionsTypes.IMPORT_TERMINALS]: true,
	[transactionsTypes.IMPORT_TRANSACTIONS]: true,
};

const runCensorInterceptorEffect = createCensorInterceptorEffect();

export { runCensorInterceptorEffect };
