import axios from 'axios';

import { getAuthParams } from '@config';
import { getUserData } from '@utils/app';
import { detectIsLocalhost } from '@utils/env';

const API_ENDPOINT = '/snc-goals-services/api/v1/goals';
const goalsApi = {
	achieveGoal: (options: AchieveGoalOptions) => {
		if (isLocalhost) return;
		return new Promise<void>(async (resolve, reject) => {
			const {
				goalID,
				tenantID,
				userID,
				clientApplicationID,
				registrationMethodID = 1,
				securitySessionID = -1,
			} = options;

			try {
				const formData = new FormData();

				formData.append(
					'goalAchievement',
					JSON.stringify({
						goalID,
						tenantID,
						userID,
						clientApplicationID,
						registrationMethodID,
						securitySessionID,
					}),
				);
				await axios({
					method: 'post',
					url: `${API_ENDPOINT}/goal`,
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					data: formData,
				});
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	},
	achieveStep: (goalStepID: GoalStep) => {
		if (isLocalhost) return;
		return new Promise<void>(async resolve => {
			try {
				const { tenantID, userID } = getUserData();
				const { clientApplication } = getAuthParams();
				const clientApplicationID = clientApplicationIDsMap[clientApplication] || -1;
				const registrationMethodID = 1;
				const securitySessionID = -1;
				const formData = new FormData();

				formData.append(
					'goalStepAchievement',
					JSON.stringify({
						goalStepID,
						tenantID,
						userID,
						clientApplicationID,
						registrationMethodID,
						securitySessionID,
					}),
				);

				await axios({
					method: 'post',
					url: `${API_ENDPOINT}/goal-step`,
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					data: formData,
				});
				resolve();
			} catch (error) {
				console.log(error);
			}
		});
	},
};

type AchieveGoalOptions = {
	goalID: number;
	tenantID: number;
	userID: number;
	clientApplicationID: number;
	registrationMethodID?: number;
	securitySessionID?: number;
};

export enum GoalStep {
	CASHFLOW_REPORT_ENTER = 3,
	CASHFLOW_REPORT_FILTER = 4,
	CASHFLOW_REPORT_LEAVE = 5,
	ACCRUAL_REPORT_ENTER = 6,
	ACCRUAL_REPORT_FILTER = 7,
	ACCRUAL_REPORT_LEAVE = 8,
	PROFIT_LOSS_REPORT_ENTER = 9,
	PROFIT_LOSS_REPORT_FILTER = 10,
	PROFIT_LOSS_REPORT_LEAVE = 11,
	BUSINESS_UNIT_EDIT_INIT = 12,
	BUSINESS_UNIT_EDIT_CONFIRM = 13,
}

const clientApplicationIDsMap = {
	web_bfm: 1,
	web_invoice: 2,
	web_ba: 3,
	web_invoice_sberbank: 13,
};

const isLocalhost = detectIsLocalhost();

export { goalsApi };
