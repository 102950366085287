import React, { useEffect } from 'react';

import { formatEntity, formatRegisterNumberByMask, getDrawer } from '@utils';
import { DrawerZone } from '@funds-registers/models';
import { selectLastImportedAccountData, selectLastImportedFileData } from '@integrations/selectors';
import { SelectDate } from '@funds-registers/components/cms-import-period-select';
import { Root, ContentLayout, WrapLayout, InfoLayout, Title, Text, ProgressBarLayout } from './styled';
import { ProgressBar } from '@ui/progress-bar';
import { FormAppearance as SelectDateFormAppearance } from '@funds-registers/components/cms-import-period-select/model';

export type XImportProgressProps = {
	zone: DrawerZone;
	progress: number;
	lastImportedAccountData: ReturnType<typeof selectLastImportedAccountData>;
};

const XImportProgress: React.FC<XImportProgressProps> = props => {
	const { zone, progress, lastImportedAccountData } = props;

	useEffect(() => {
		getDrawer().setContent({
			footer: null,
		});
	}, []);

	const isImportCmsProgress = zone === DrawerZone.IMPORT_CMS_PROGRESS || zone === DrawerZone.REFRESH_CMS_PROGRESS;

	const renderSelectDate = () => {
		if (isImportCmsProgress) {
			return (
				<SelectDate
					appearance={
						zone === DrawerZone.IMPORT_CMS_PROGRESS
							? SelectDateFormAppearance.IMPORT_EXTERNAL
							: SelectDateFormAppearance.REFRESH_PAYMENTS
					}
					disabled
				/>
			);
		}
		return null;
	};

	const renderTitle = () => {
		switch (zone) {
			case DrawerZone.IMPORT_CMS_PROGRESS:
				return 'Импортируются данные счёта';
			case DrawerZone.REFRESH_CMS_PROGRESS:
				return 'Идет обновление платежей по счёту';
			case DrawerZone.IMPORT_FILE_PROGRESS:
				return 'Импортируется файл';
		}
	};

	const renderEntityName = () => {
		if (isImportCmsProgress) {
			const formattedEntityName = formatEntity(lastImportedAccountData.legalEntityName);
			if (formattedEntityName) {
				return <Text>{formattedEntityName}</Text>;
			}
		}
		return null;
	};

	const renderText = () => {
		if (isImportCmsProgress) {
			return `Счёт № ${formatRegisterNumberByMask(lastImportedAccountData.registerNumber)}`;
		}
		return null;
	};

	return (
		<Root>
			{renderSelectDate()}
			<ContentLayout>
				<WrapLayout>
					<InfoLayout>
						<Title>{renderTitle()}</Title>
						{renderEntityName()}
						<Text>{renderText()}</Text>
						<Text>Операция может занять несколько минут</Text>
					</InfoLayout>
					<ProgressBarLayout>
						<ProgressBar value={progress} />
					</ProgressBarLayout>
				</WrapLayout>
			</ContentLayout>
		</Root>
	);
};

export { XImportProgress };
