import React from 'react';

import { Ripple, RippleProps } from '@ui/ripple';
import { withTheme, WithThemeProps, Theme } from '@theme';
import withConfirm, { ConfirmEnhancerProps } from '@core/hocs/with-confirm.hoc';
import { OkIcon } from '@ui/icons/ok';
import { Button, ButtonContent } from './styled';

export type RaisedButtonProps = {
	appearance?: 'contained' | 'text' | 'outlined';
	color?: 'primary' | 'white' | 'black';
	size?: 'micro' | 'small' | 'medium' | 'large';
	fullWidth?: boolean;
	stopPropagation?: boolean;
	RippleProps?: Partial<RippleProps>;
	type?: 'default' | 'primary'; // deprecated
	icon?: React.ReactNode; // deprecated
	label?: React.ReactNode; // deprecated
	htmlType?: 'button' | 'submit'; // deprecated
	component?: string;
	children?: React.ReactNode;
	onTouchTap?: React.MouseEventHandler<{}>;
	onClick?: React.MouseEventHandler<{}>;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'> &
	WithThemeProps;

class RaisedButton extends React.PureComponent<RaisedButtonProps> {
	static defaultProps = {
		appearance: 'contained',
		type: 'default',
		htmlType: 'button',
		color: 'white',
		size: 'medium',
		component: 'button',
		RippleProps: {},
	} as any;
	ref: HTMLButtonElement = null;

	setRef = (ref: HTMLButtonElement) => (this.ref = ref);

	handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		const { stopPropagation, disabled, onClick, onTouchTap } = this.props;

		this.ref && this.ref.blur();

		e.persist();

		if (stopPropagation) {
			e.stopPropagation();
		}

		if (disabled) return;

		setTimeout(() => {
			typeof onClick === 'function' && onClick(e);
			typeof onTouchTap === 'function' && onTouchTap(e);
		});
	};

	render() {
		const {
			appearance,
			disabled,
			color,
			size,
			RippleProps,
			fullWidth,
			icon,
			type,
			htmlType,
			label,
			component,
			theme,
			children,
			onClick,
			onTouchTap,
			...rest
		} = this.props;
		const calculatedColor = type === 'primary' ? 'primary' : color;
		const calculatedRippleColor =
			appearance === 'text'
				? color === 'primary'
					? 'textPrimary'
					: color
				: appearance === 'outlined'
				? color === 'primary'
					? 'textPrimary'
					: color
				: color;
		const rippleProps = getRippleEffectProps(calculatedRippleColor, theme);
		const slot = icon ? (
			<ButtonContent size={size}>
				{icon}
				<span>{label || children}</span>
			</ButtonContent>
		) : (
			label || children
		);

		return (
			<Button
				{...rest}
				tabIndex={0}
				component={component}
				type={htmlType}
				appearance={appearance}
				color={calculatedColor}
				size={size}
				disabled={disabled}
				fullWidth={fullWidth}
				onSetRef={this.setRef}
				onClick={this.handleClick}>
				<Ripple {...rippleProps} {...RippleProps} skip={disabled}>
					{icon ? slot : <ButtonContent size={size}>{slot}</ButtonContent>}
				</Ripple>
			</Button>
		);
	}
}

const getRippleEffectProps = (key: string, theme: Theme) => {
	const props: Record<string, Partial<RippleProps>> = {
		white: {},
		primary: {
			color: 'rgba(255, 255, 255, 0.7)',
		},
		textPrimary: {
			color: theme.palette.accent,
		},
	};

	return props[key];
};

const XRaisedButton = withTheme(RaisedButton) as React.ComponentType<RaisedButtonProps>;

const EnhancedRaisedButton = withConfirm<RaisedButtonProps>()(XRaisedButton);

export type RaisedButtonConfirmProps = RaisedButtonProps & ConfirmEnhancerProps;

const RaisedButtonConfirm: React.FC<RaisedButtonConfirmProps> = props => {
	return <EnhancedRaisedButton {...props} renderConfirmContent={() => <OkIcon color='alarm' />} />;
};

export { XRaisedButton as RaisedButton, RaisedButtonConfirm };
