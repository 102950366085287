import { createTypes } from '@flux';

type ActionTypes = {
	ADD_INVOICE: string;
	AUTOINSERT_TO_NEW_INVOICE: string;
	CLEAR_INVOICE_NOTIFICATION_HISTORY: string;
	EXPORT_DOCUMENT_TO_DIADOC: string;
	EXPORT_DOCUMENT_TO_DOC: string;
	EXPORT_DOCUMENT_TO_PDF: string;
	FETCH_HAS_SINGLE_COMPANY: string;
	FETCH_INVOICE_NOTIFICATION_HISTORY: string;
	FETCH_INVOICES: string;
	FETCH_IS_CONTACTS_ENABLED: string;
	FETCH_IS_PRODUCTS_ENABLED: string;
	FETCH_LAST_INVOICE: string;
	FETCH_LAST_SIGN_EMPLOYEES_CHOICE: string;
	INVALIDATE_INVOICES: string;
	LINK_PAYMENTS_TO_INVOICE: string;
	REMOVE_DRAFT: string;
	RESET_FILTERS: string;
	REVOKE_INVOICE: string;
	SET_DATE_RANGE: string;
	SET_FILTER_BY_CLIENTS: string;
	SET_FILTER_BY_DOCUMENTS: string;
	SET_FILTER_BY_MANAGERS: string;
	SET_FILTER_BY_SEGMENT: string;
	SET_FILTER_BY_TENANT_ENTITIES: string;
	SET_FILTER_BY_TEXT: string;
	SET_IS_CONTACTS_ENABLED: string;
	SET_IS_PRODUCTS_ENABLED: string;
	SET_LAST_SIGN_EMPLOYEES_CHOICE: string;
	SET_RESPONSIBLE_EMPLOYEE: string;
	SHARE_INVOICE: string;
	SYNC_PAYMENTS_WITH_INVOICES: string;
	UPDATE_INVOICE: string;
};

const types = createTypes<ActionTypes>(
	[
		'ADD_INVOICE',
		'AUTOINSERT_TO_NEW_INVOICE',
		'CLEAR_INVOICE_NOTIFICATION_HISTORY',
		'EXPORT_DOCUMENT_TO_DIADOC',
		'EXPORT_DOCUMENT_TO_DOC',
		'EXPORT_DOCUMENT_TO_PDF',
		'FETCH_HAS_SINGLE_COMPANY',
		'FETCH_INVOICE_NOTIFICATION_HISTORY',
		'FETCH_INVOICES',
		'FETCH_IS_CONTACTS_ENABLED',
		'FETCH_IS_PRODUCTS_ENABLED',
		'FETCH_LAST_INVOICE',
		'FETCH_LAST_SIGN_EMPLOYEES_CHOICE',
		'INVALIDATE_INVOICES',
		'LINK_PAYMENTS_TO_INVOICE',
		'REMOVE_DRAFT',
		'RESET_FILTERS',
		'REVOKE_INVOICE',
		'SET_DATE_RANGE',
		'SET_FILTER_BY_CLIENTS',
		'SET_FILTER_BY_DOCUMENTS',
		'SET_FILTER_BY_MANAGERS',
		'SET_FILTER_BY_SEGMENT',
		'SET_FILTER_BY_TENANT_ENTITIES',
		'SET_FILTER_BY_TEXT',
		'SET_IS_CONTACTS_ENABLED',
		'SET_IS_PRODUCTS_ENABLED',
		'SET_LAST_SIGN_EMPLOYEES_CHOICE',
		'SET_RESPONSIBLE_EMPLOYEE',
		'SHARE_INVOICE',
		'SYNC_PAYMENTS_WITH_INVOICES',
		'UPDATE_INVOICE',
	],
	'😀NEW_INVOICE',
);

export { types };
