import React, { memo, useEffect } from 'react';

import { selectFirstAccountStatementImportInProgress, selectSupportedCMS } from '@funds-registers/selectors';
import {
	selectAsyncCashManagementSystems,
	selectAsyncLastAccountStatementImports,
	selectLastAccountStatementImportsMap,
} from '@integrations/selectors';
import { useAutoFetch, useMapDispatch, useMapState } from '@core/flux';
import { mainIntegrationsActionsPack } from '@integrations/actions';
import { getPropInSafe } from '@utils';
import { invalidateLastAccountStatementImports } from '@integrations/actions/invalidators';
import { XSyncMarker, XSyncMarkerProps } from './sync-marker.view';

type SyncMarkerProps = Pick<XSyncMarkerProps, 'fundsRegisterID' | 'connectedToCMS'> &
	Partial<Pick<XSyncMarkerProps, 'tooltipPosition' | 'needSyncButtonIfFailed' | 'onAction' | 'bankName'>>;

const SyncMarker: React.FC<SyncMarkerProps> = memo(props => {
	const {
		fundsRegisterID,
		connectedToCMS,
		bankName,
		tooltipPosition = 'right',
		needSyncButtonIfFailed = true,
		onAction,
	} = props;
	const [lastAccountStatementImportsMap, supportedCMS, firstAsiInProgress] = useMapState([
		selectLastAccountStatementImportsMap,
		selectSupportedCMS,
		selectFirstAccountStatementImportInProgress,
	]);
	const [fetchLastAccountStatementImportList, selectCMS, fetchCMS, initPeriodicFetchASIList] = useMapDispatch([
		mainIntegrationsActionsPack.fetchLastAccountStatementImports,
		mainIntegrationsActionsPack.setSelectedCmsID,
		mainIntegrationsActionsPack.fetchCashManagementSystems,
		invalidateLastAccountStatementImports,
	]);

	useAutoFetch({
		selector: selectAsyncCashManagementSystems.selectDidInvalidate,
		fetch: () => fetchCMS(),
	});

	useAutoFetch({
		selector: selectAsyncLastAccountStatementImports.selectDidInvalidate,
		fetch: () => fetchLastAccountStatementImportList(),
	});

	useEffect(() => {
		let periodicFetchInterval = null;
		const asiInProgressFrID = getPropInSafe(firstAsiInProgress, o => o.FundsRegisterID);
		if (asiInProgressFrID === fundsRegisterID) {
			clearInterval(periodicFetchInterval);
			periodicFetchInterval = setInterval(() => {
				initPeriodicFetchASIList();
			}, 10000);
		} else {
			clearInterval(periodicFetchInterval);
		}
		return () => clearInterval(periodicFetchInterval);
	}, []);

	return (
		<XSyncMarker
			fundsRegisterID={fundsRegisterID}
			connectedToCMS={connectedToCMS}
			lastAccountStatementImportsMap={lastAccountStatementImportsMap}
			bankName={bankName}
			tooltipPosition={tooltipPosition}
			supportedCMS={supportedCMS}
			needSyncButtonIfFailed={needSyncButtonIfFailed}
			selectCMS={selectCMS}
			onAction={onAction}
		/>
	);
});

export { SyncMarker };
