export enum TransactionSlice {
	TURNOVER = 'TURNOVER',
	FAILED = 'FAILED',
	COMMISION = 'COMMISION',
	CHARGEBACK = 'CHARGEBACK',
	REVENUE = 'REVENUE',
}

export enum TransactionOperationType {
	INCOME = 'INCOME',
	CHARGEBACK = 'CHARGEBACK',
}

export const TransactionOperationTypeName = {
	INCOME: 'Зачисление',
	CHARGEBACK: 'Возврат',
};
