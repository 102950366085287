import { createTypes, createStaticAction, createAsyncAction, RECEIVE } from '@flux';
import { ImportTransactionsOptions as ApiImportTransactionsOptions } from '@core/api/funds';
import { createNotificationMessage } from '@utils/notifications';
import { mainTransactionsSelectorsPack } from '@transactions/selectors';

type ActionTypes = {
	SET_DATE_RANGE: string;
	SET_FILTER_BY_OPERATION_TYPES: string;
	SET_FILTER_BY_TERMINALS: string;
	RESET_FILTERS: string;
	FETCH_TURNOVERS: string;
	FETCH_TRANSACTIONS: string;
	FETCH_TERMINALS: string;
	IMPORT_TERMINALS: string;
	IMPORT_TRANSACTIONS: string;
};

export const types = createTypes<ActionTypes>(
	[
		'SET_DATE_RANGE',
		'SET_FILTER_BY_OPERATION_TYPES',
		'SET_FILTER_BY_TERMINALS',
		'RESET_FILTERS',
		'FETCH_TURNOVERS',
		'FETCH_TRANSACTIONS',
		'FETCH_TERMINALS',
		'IMPORT_TERMINALS',
		'IMPORT_TRANSACTIONS',
	],
	'TRANSACTIONS',
);

const actions = {
	setDateRange: (dateRange: DateRange) => createStaticAction(types.SET_DATE_RANGE)(dateRange),
	setFilterByOpeartionTypes: (values: Array<string>) => createStaticAction(types.SET_FILTER_BY_OPERATION_TYPES)(values),
	setFilterByTerminals: (values: Array<string>) => createStaticAction(types.SET_FILTER_BY_TERMINALS)(values),
	resetFilters: () => createStaticAction(types.RESET_FILTERS)(),
	fetchTurnovers: createAsyncAction(
		types.FETCH_TURNOVERS,
		(api, _, __, dateRange: DateRange) => {
			return new Promise<Array<PosTerminalTransactionTurnover>>(resolve => {
				api.fundsPack.acquiring.fetchTurnovers(dateRange).then(result => {
					resolve(result);
				});
			});
		},
		{
			isValidSelector: mainTransactionsSelectorsPack.selectAsyncTurnovers.selectIsValid,
			isFetchingSelector: mainTransactionsSelectorsPack.selectAsyncTurnovers.selectIsFetching,
		},
	) as (dateRange: DateRange) => void,
	fetchTransactions: createAsyncAction(
		types.FETCH_TRANSACTIONS,
		(api, _, __, dateRange: DateRange) => {
			return new Promise<Array<PosTerminalTransaction>>(resolve => {
				api.fundsPack.acquiring.fetchTransactions(dateRange).then(result => {
					resolve(result);
				});
			});
		},
		{
			isValidSelector: mainTransactionsSelectorsPack.selectAsyncTransactions.selectIsValid,
			isFetchingSelector: mainTransactionsSelectorsPack.selectAsyncTransactions.selectIsFetching,
		},
	) as (dateRange: DateRange) => void,
	importTransactions: createAsyncAction(
		types.IMPORT_TRANSACTIONS,
		(api, _, __, options: ImportTransactionsOptions) => {
			const { onComplete = () => {} } = options;

			return new Promise<Array<PosTerminalTransaction>>(resolve => {
				api.fundsPack.acquiring.importTransactions(options).then(result => {
					resolve(result);
					onComplete();
				});
			});
		},
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Транзакции импортированы 😊', 'success'),
		},
	) as (options: ImportTransactionsOptions) => void,
	fetchTerminals: createAsyncAction(
		types.FETCH_TERMINALS,
		api => {
			return new Promise<Array<PosTerminal>>(resolve => {
				api.fundsPack.acquiring.fetchTerminals().then(result => {
					resolve(result);
				});
			});
		},
		{
			isValidSelector: mainTransactionsSelectorsPack.selectAsyncTerminals.selectIsValid,
			isFetchingSelector: mainTransactionsSelectorsPack.selectAsyncTerminals.selectIsFetching,
		},
	) as () => void,
	importTerminals: createAsyncAction(
		types.IMPORT_TERMINALS,
		(api, _, __, options: ImportTerminalsOptions) => {
			const { onComplete = () => {} } = options;

			return new Promise<Array<PosTerminal>>(resolve => {
				api.fundsPack.acquiring.importTerminals().then(result => {
					resolve(result);
					onComplete();
				});
			});
		},
		{
			showMessage: type => type === RECEIVE && createNotificationMessage('Торговые точки импортированы 😊', 'success'),
		},
	) as (options: ImportTerminalsOptions) => void,
};

type ImportTransactionsOptions = {
	onComplete: () => void;
} & ApiImportTransactionsOptions;

type ImportTerminalsOptions = {
	onComplete: () => void;
};

export const mainTransactionsActionsPack = {
	types,
	actions,
};

export { types as mainTransactionsActionTypes };
