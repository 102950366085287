import React, { useRef } from 'react';
import * as moment from 'moment';

import { bankHasCMS, getCMSGUID, getPropInSafe } from '@core/libs/utils';
import { RaisedButton } from '@ui/raised-button';
import { StatefulTooltip } from '@ui/tooltip';
import * as statuses from '@shared/constants/statuses';
import { selectSupportedCMS } from '@funds-registers/selectors';
import { selectLastAccountStatementImportsMap } from '@integrations/selectors';
import { useTheme } from '@theme';
import { SyncIcon } from '@ui/icons/sync';
import { Root, Trigger, IconLayout, ContentLayout, Sign, ButtonLayout, tooltipStyle } from './styled';

export type XSyncMarkerProps = {
	fundsRegisterID: number;
	connectedToCMS: boolean;
	lastAccountStatementImportsMap: ReturnType<typeof selectLastAccountStatementImportsMap>;
	bankName: string;
	tooltipPosition: 'right' | 'left' | 'top' | 'bottom';
	supportedCMS: ReturnType<typeof selectSupportedCMS>;
	needSyncButtonIfFailed: boolean;
	selectCMS: (ID: number) => void;
	onAction: (status: string, ID: number) => void;
};

const formateDate = date => moment(date, 'DD-MM-YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm');

const XSyncMarker: React.FC<XSyncMarkerProps> = props => {
	const {
		fundsRegisterID,
		connectedToCMS,
		lastAccountStatementImportsMap,
		bankName,
		tooltipPosition,
		supportedCMS,
		needSyncButtonIfFailed,
		selectCMS,
		onAction,
	} = props;
	const triggerRef = useRef<HTMLDivElement>(null);
	const { theme } = useTheme();

	const handleClick = e => e.stopPropagation();

	const handleAction = (status: string) => e => {
		if (status === 'CAN_CONNECT') {
			const CMSGUID = getCMSGUID(bankName);
			const CMS = supportedCMS.find(item => {
				return item.SubsystemInstanceGUID === CMSGUID;
			});
			CMS && selectCMS(CMS.ID);
		}
		onAction && onAction(status, fundsRegisterID);
		e.stopPropagation();
	};

	const renderContent = (connectedToCMS: boolean, bankCanConnectToCMS: boolean) => {
		const importStatistics = lastAccountStatementImportsMap[fundsRegisterID];

		if (connectedToCMS) {
			if (!importStatistics) {
				return (
					<ContentLayout>
						<Sign>Синхронизация данных не выполнялась</Sign>
						<ButtonLayout>
							<RaisedButton appearance='text' color='primary' size='small' onClick={handleAction('SYNC')}>
								Синхронизировать
							</RaisedButton>
						</ButtonLayout>
					</ContentLayout>
				);
			} else {
				const { ImportStatusCode: status, ImportStartDate: dateStart, ImportFinishDate: dateEnd } = importStatistics;

				if (status === statuses.DUPLICATE_REQUEST) {
					return (
						<ContentLayout>
							<Sign>Процесс синхронизации уже запущен</Sign>
						</ContentLayout>
					);
				}

				if (status === statuses.IN_PROGRESS) {
					return (
						<ContentLayout>
							<Sign>Идет синхронизация</Sign>
							<Sign>{formateDate(dateStart)}</Sign>
						</ContentLayout>
					);
				}

				if (status === statuses.SUCCESS) {
					return (
						<ContentLayout>
							<Sign>Последняя синхронизация</Sign>
							<Sign>{formateDate(dateEnd)}</Sign>
							<ButtonLayout>
								<RaisedButton appearance='text' color='primary' size='small' onClick={handleAction('SYNC')}>
									Синхронизировать
								</RaisedButton>
							</ButtonLayout>
						</ContentLayout>
					);
				}

				if (status === statuses.FAILED || status === statuses.FINISHED_WITH_WARNING) {
					return (
						<ContentLayout>
							<Sign>Последняя синхронизация</Sign>
							<Sign>{formateDate(dateStart)}</Sign>
							<Sign alarm>
								Технические проблемы
								<StatefulTooltip position='bottom'>{importStatistics.Message || 'Неизвестная ошибка'}</StatefulTooltip>
							</Sign>
							{needSyncButtonIfFailed && (
								<ButtonLayout>
									<RaisedButton appearance='text' color='primary' size='small' onClick={handleAction('SYNC')}>
										Синхронизировать
									</RaisedButton>
								</ButtonLayout>
							)}
						</ContentLayout>
					);
				}
			}
		}

		if (bankCanConnectToCMS) {
			return (
				<ContentLayout>
					<Sign>Этот счёт можно подключить к банку</Sign>
					<ButtonLayout>
						<RaisedButton appearance='text' color='primary' size='small' onClick={handleAction('CAN_CONNECT')}>
							Подключить
						</RaisedButton>
					</ButtonLayout>
				</ContentLayout>
			);
		}

		return null;
	};

	const bankCanConnectToCMS = !connectedToCMS && bankHasCMS(bankName);
	const status = getPropInSafe(lastAccountStatementImportsMap[fundsRegisterID], o => o.ImportStatusCode, '');

	if (connectedToCMS || bankCanConnectToCMS) {
		return (
			<Root>
				<Trigger ref={triggerRef} onClick={handleClick} status={connectedToCMS ? status : 'NOT_CONNECTED'}>
					<IconLayout status={connectedToCMS ? status : 'NOT_CONNECTED'}>
						<SyncIcon color='current' size={14} />
					</IconLayout>
					<StatefulTooltip
						appearance='box'
						position={tooltipPosition}
						style={{ ...tooltipStyle, backgroundColor: theme.palette.canvas, color: theme.palette.text }}>
						{renderContent(connectedToCMS, bankCanConnectToCMS)}
					</StatefulTooltip>
				</Trigger>
			</Root>
		);
	}

	return null;
};

export { XSyncMarker };
