import React, { useLayoutEffect } from 'react';

import { fundsApi } from '@core/api/funds';
import { DrawerFooterControls } from '@ui/drawer';
import { getDrawer, getFundsRegisterType } from '@utils';
import { SelectDate } from '@funds-registers/components/cms-import-period-select';
import { RadioButton, RadioButtonGroupMUI as RadioButtonGroup } from '@ui/radio-button';
import {
	ContentLayout,
	DateRangeLayout,
	FormLayout,
	radioButtonGroupStyle,
	radioButtonLabelStyle,
	RadioButtonLayout,
	radioButtonStyle,
	Root,
} from './styled';
import { selectFundsRegisterForRefresh } from '@funds-registers/selectors';
import { mainIntegrationsActionsPack } from '@integrations/actions';
import { FormAppearance as SelectDateFormAppearance } from '@funds-registers/components/cms-import-period-select/model';
import { FormAppearance } from './model';
import { CMSDataType } from '@funds-registers/models';

type XCmsRefreshProps = {
	isFetching: boolean;
	fundsRegisterForRefresh: ReturnType<typeof selectFundsRegisterForRefresh>;
	appearance: FormAppearance;
	currenciesMap: Record<string, Currency>;
	setAppearance: React.Dispatch<React.SetStateAction<FormAppearance>>;
	onStartRefreshAccountFromCMS: (cmsAccount: CMSAccount, cmsGUID: string, dataType: CMSDataType) => void;
	setLastImportedAccountData: typeof mainIntegrationsActionsPack.setLastImportedAccountData;
};

const XCmsRefresh: React.FC<XCmsRefreshProps> = props => {
	const {
		isFetching,
		fundsRegisterForRefresh,
		appearance,
		currenciesMap,
		setAppearance,
		onStartRefreshAccountFromCMS,
		setLastImportedAccountData,
	} = props;

	useLayoutEffect(() => {
		getDrawer().setContent({
			title: 'Обновление платежей по счёту из банка',
			footer: (
				<DrawerFooterControls
					actionLabel='Импорт'
					canSubmit={!isFetching}
					onAction={handleStartRefresh}
					onClose={handleClose}
				/>
			),
		});
	}, [isFetching]);

	const handleClose = () => getDrawer().close();

	const handleChangeRadioGroup = (_, value) => {
		setAppearance(value);
	};

	const handleStartRefresh = () => {
		if (fundsRegisterForRefresh) {
			const cmsGUID = fundsRegisterForRefresh.CashManagementSystem.SubsystemInstanceGUID;
			const bankAccountFundsRegister = fundsRegisterForRefresh as BankAccountFundsRegister;
			const fundsRegisterType = getFundsRegisterType(fundsRegisterForRefresh);
			const isCardAccount = fundsRegisterType === 'card';
			const cmsAccount = {
				...new fundsApi.package.CMSAccount(),
				Status: 1, // open
				UID: bankAccountFundsRegister.SourceUID,
				BIC: bankAccountFundsRegister.Bank?.Bic,
				AccountNumber: fundsRegisterForRefresh.RegisterNumber,
				AccountName: fundsRegisterForRefresh.Name,
				AccountType: isCardAccount ? 'CARD' : 'BANK_ACCOUNT',
				AccountCurrencyLetterCode: currenciesMap[fundsRegisterForRefresh.CurrencyID].LetterCode,
				LegalEntityName: fundsRegisterForRefresh.LegalEntity?.Name || '',
				LegalEntityUID: fundsRegisterForRefresh.LegalEntity?.GUID || '',
				ExternalSystemAccountID: fundsRegisterForRefresh.ExternalSystemAccountID,
			};
			onStartRefreshAccountFromCMS(
				cmsAccount,
				cmsGUID,
				isCardAccount ? CMSDataType.NATURAL_PERSON_CARD_TRANSACTION : CMSDataType.LEGAL_ENTITY_BANK_ACCOUNT,
			);
			setLastImportedAccountData({
				registerNumber: fundsRegisterForRefresh.RegisterNumber,
				legalEntityName: fundsRegisterForRefresh.LegalEntity?.Name || '',
			});
		}
	};

	return (
		<Root>
			<ContentLayout>
				<FormLayout>
					<RadioButtonLayout>
						<RadioButtonGroup
							name='appearance'
							defaultSelected={appearance}
							style={radioButtonGroupStyle}
							onChange={handleChangeRadioGroup}>
							<RadioButton
								value={FormAppearance.UNLOADED}
								label='Импортировать платежи еще не загруженные в систему'
								style={radioButtonStyle}
								labelStyle={radioButtonLabelStyle}
							/>
							<RadioButton
								value={FormAppearance.DATE_RANGE}
								label='Обновить платежи за выбранный период'
								style={radioButtonStyle}
								labelStyle={radioButtonLabelStyle}
							/>
						</RadioButtonGroup>
					</RadioButtonLayout>
				</FormLayout>
			</ContentLayout>
			{appearance === FormAppearance.DATE_RANGE && (
				<DateRangeLayout>
					<SelectDate appearance={SelectDateFormAppearance.REFRESH_PAYMENTS} />
				</DateRangeLayout>
			)}
		</Root>
	);
};

export { XCmsRefresh };
