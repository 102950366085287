import { InvoiceStatusCode } from '@utils/invoice';

export enum InvoiceStatisticsSliceCode {
	ISSUED = 'ISSUED',
	PAYED = 'PAYED',
	DEBT = 'DEBT',
}

export enum InvoiceSegmentCode {
	ISSUED = 'ISSUED',
	UNPAID = 'UNPAID',
	PAYED = 'PAYED',
	OVERDUE = 'OVERDUE',
	DRAFT = 'DRAFT',
}

export enum InvoiceDocumentsAvailability {
	YES = 'YES',
	NO = 'NO',
}

export const invoiceStatusNameMap = {
	[InvoiceStatusCode.DRAFT]: 'Черновик',
	[InvoiceStatusCode.NOT_PAID]: 'Не оплачен',
	[InvoiceStatusCode.PARTIALLY_PAID]: 'Частично оплачен',
	[InvoiceStatusCode.SENT_TO_CMS]: 'В оплате',
	[InvoiceStatusCode.PAID]: 'Оплачен',
	[InvoiceStatusCode.OVERDUE]: 'Просрочен',
};

export enum InvoiceSearchParameter {
	TO = 'to',
}

export enum InvoiceIssueVariant {
	LEGAL_ENTITY = 'legal-entity',
	NATURAL_PERSON = 'natural-person',
}

export enum SettingsKey {
	IS_CONTACTS_ENABLED = '(invoices)is_contacts_enabled',
	IS_PRODUCTS_ENABLED = '(invoices)is_products_enabled',
	HAS_SINGLE_COMPANY = '(invoices)has_single_company',
	LAST_SIGN_EMPLOYEES_CHOICE = '(invoices)last_sign_employees_choice',
}

export enum DocumentTypeID {
	ACCEPTANCE_ACT = 2,
	ACCEPTANCE_ACT_NATURAL_PERSON = 12,
	DELIVERY_NOTE = 1,
	INVOICE = 7,
	INVOICE_FACT = 8,
	UNI_TRANSFER = 9,
}
