/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/prefer-regexp-exec */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-var */
import { setYandexClientID } from '@config';

export type IdentificationOptions = {
	name: string;
	tenantID: number;
	login: string;
	userID: number;
	isTrial: boolean;
	trialDays: number;
	contactPersonName: string;
	contactEmail: string;
	contactPhone: string;
	visitedURL: string;
};

function injectYandexMetrika(counterID: number) {
	if (typeof counterID === 'undefined') return;
	(function (m, e, t, r, i, k, a) {
		m[i] =
			m[i] ||
			function () {
				(m[i].a = m[i].a || []).push(arguments);
			};
		m[i].l = 1 * (new Date() as any);
		(k = e.createElement(t)),
			(a = e.getElementsByTagName(t)[0]),
			(k.async = 1),
			(k.src = r),
			a.parentNode.insertBefore(k, a);
	})(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

	window['ym'](counterID, 'init', {
		triggerEvent: true,
		clickmap: true,
		trackLinks: true,
		accurateTrackBounce: true,
		webvisor: true,
		ecommerce: 'dataLayer',
		trackHash: true,
	});
	window['ym'](counterID, 'getClientID', clientID => {
		setYandexClientID(clientID);
	});
}

function injectGoogleTagManager(counterID: string, tenantID: number) {
	if (typeof counterID === 'undefined') return;
	window['dataLayer'] = window['dataLayer'] || [];
	window['dataLayer'].push({ 's_USER-ID': tenantID });

	(function (w, d, s, l, i) {
		w[l] = w[l] || [];
		w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
		var f = d.getElementsByTagName(s)[0],
			j = d.createElement(s),
			dl = l != 'dataLayer' ? '&l=' + l : '';
		j['async'] = true;
		j['src'] = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
		f.parentNode.insertBefore(j, f);
	})(window, document, 'script', 'dataLayer', counterID);

	const noscript = document.createElement('noscript');
	const iframe = document.createElement('iframe');

	iframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${counterID}`);
	iframe.setAttribute('height', '0');
	iframe.setAttribute('width', '0');
	iframe.setAttribute('style', 'display:none;visibility:hidden');
	noscript.appendChild(iframe);
	document.body.appendChild(noscript);

	window['sendActionToGoogleTagManager'] = e => {
		window['dataLayer'].push(e);
	};
}

function injectCarrotQuest(counterID: string) {
	if (typeof counterID === 'undefined') return;
	function getCookie(name) {
		const matches = document.cookie.match(
			new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'),
		);
		return matches ? decodeURIComponent(matches[1]) : undefined;
	}

	function removeCarrotQuestDataFromLocalStorage() {
		for (const key in localStorage) {
			if (localStorage.hasOwnProperty(key) && key.indexOf('carrotquest') !== -1) {
				localStorage.removeItem(key);
			}
		}
	}

	if (!getCookie('carrotquest_uid')) {
		removeCarrotQuestDataFromLocalStorage();
	}

	function Build(name) {
		return function () {
			window.carrotquestasync.push(name, arguments);
		};
	}

	if (typeof window.carrotquest === 'undefined') {
		var s = document.createElement('script');
		s.type = 'text/javascript';
		s.async = true;
		s.src = '//cdn.carrotquest.io/api.min.js';
		var x = document.getElementsByTagName('head')[0];
		x.appendChild(s);
		window.carrotquest = {};
		window.carrotquestasync = [];
		window.carrotquest.settings = {};
		var m = [
			'connect',
			'track',
			'identify',
			'auth',
			'open',
			'onReady',
			'addCallback',
			'removeCallback',
			'trackMessageInteraction',
		];
		for (var i = 0; i < m.length; i++) window.carrotquest[m[i]] = Build(m[i]);
	}

	window.carrotquest.onReady(() => window.carrotquest.removeChat());
	window.carrotquest.connect(counterID);
}

function runCarrotQuestIndetification(options: IdentificationOptions) {
	const { name, contactEmail, contactPhone, tenantID, login, isTrial, trialDays, contactPersonName, visitedURL } =
		options;

	if (typeof window.carrotquest !== 'undefined') {
		window.carrotquest.identify({
			$name: name,
			$email: contactEmail,
			$phone: contactPhone,
			tenantID: tenantID,
			login: login,
			isTrial: isTrial,
			trialDays: trialDays,
			contactPersonName: contactPersonName,
			visitedURL: visitedURL,
		});

		console.log('indentify: ', options);
	}
}

function setYandexMetrikaUserID(counterID: number, tenantID: number, userID: number) {
	if (typeof window['ym'] === 'function') {
		typeof counterID === 'number' && window['ym'](counterID, 'setUserID', `${tenantID}-${userID}`);
	}
}

function takeYaMetriksTarget(counterID: number, targetCode: string) {
	if (typeof window['ym'] === 'function' && counterID && targetCode) {
		typeof window['ym'](counterID, 'reachGoal', targetCode);
	}
}

const YA_METRIKS_TARGETS = {
	SBERBANK_SMS_AUTH: 'sberbank_sms_auth',
	SBERBANK_TOKEN_AUTH: 'sberbank_token_auth',
	VTB_PAY: 'vtb_pay',
	OPEN_PAY: 'open_pay',
	TINKOFF_PAY: 'tinkoff_pay',
	MODULEBANK_PAY: 'modulbank_pay',
	CARD_PAY: 'bankcard_pay',
	BANNER_REGISTER: 'banner_register',
};

export {
	injectYandexMetrika,
	injectGoogleTagManager,
	injectCarrotQuest,
	runCarrotQuestIndetification,
	setYandexMetrikaUserID,
	takeYaMetriksTarget,
	YA_METRIKS_TARGETS,
};
