import { CashflowLimitsTypeModeCode } from '@cashflow-limits/models';
import cashflowlimitpackage from 'cashflowlimitpackage-api';
import { buildTLongArray } from '@utils';

const cashflowLimitsApi = {
	package: cashflowlimitpackage,
	cashflowLimits: {
		client: new cashflowlimitpackage.CashflowLimitServiceClient(),
		fetchCashflowLimits: (options: FetchCashflowLimitsOptions) => {
			const { dateRange, groupBy, typeMode, tenantEntityIDs = [], projectIDs = [] } = options;
			const request: CashflowLimitsItemsRequest = {
				...new cashflowLimitsApi.package.CashflowLimitsItemsRequest(),
				DateStart: dateRange.dateStart,
				DateFinish: dateRange.dateEnd,
				GroupingType: groupBy,
			};

			if (typeMode === CashflowLimitsTypeModeCode.LEGAL_ENTITY) {
				request.LegalEntitiesIDs = buildTLongArray(tenantEntityIDs);
			}

			if (typeMode === CashflowLimitsTypeModeCode.PROJECT) {
				request.ProjectsIDs = buildTLongArray(projectIDs);
			}

			return new Promise<CashflowLimitsItemsResponse>(resolve => {
				cashflowLimitsApi.cashflowLimits.client.getCashflowLimitsItems(request, result => {
					resolve(result);
				});
			});
		},
		updateCashflowLimit: (options: CashflowLimitUpdateOptions) => {
			const {
				typeMode,
				tenantEntityID,
				projectID,
				accountChartItemID,
				periodType,
				periodID,
				value,
				plannedOperationIDs = [],
			} = options;
			const request: CashflowLimitCreateRequest = {
				...new cashflowLimitsApi.package.CashflowLimitCreateRequest(),
				LimitType: '1', // Пока существует только один тип лимитов с кодом '1'
				AccountChartItemID: accountChartItemID,
				PeriodType: periodType,
				PeriodID: periodID,
			};

			request.Value = typeof value === 'undefined' ? null : value;

			if (plannedOperationIDs.length > 0) {
				request.PlannedOperationsIDs = buildTLongArray(plannedOperationIDs);
				request.FromPlannedOperations = true;
			}

			if (typeMode === CashflowLimitsTypeModeCode.LEGAL_ENTITY) {
				request.LegalEntityID = tenantEntityID;
			}

			if (typeMode === CashflowLimitsTypeModeCode.PROJECT) {
				request.ProjectID = projectID;
			}

			return new Promise<number>(resolve => {
				cashflowLimitsApi.cashflowLimits.client.createCashflowLimit(request, result => {
					resolve(result);
				});
			});
		},
		createCashflowLimitsFromPlan: (request: CashflowLimitsFromPlanCreateRequest) => {
			return new Promise<boolean>(resolve => {
				cashflowLimitsApi.cashflowLimits.client.createCashflowLimitsFromPlan(request, result => {
					resolve(result);
				});
			});
		},
	},
};

export type FetchCashflowLimitsOptions = {
	dateRange: DateRange;
	typeMode: CashflowLimitsTypeModeCode;
	groupBy: CashflowLimitPeriod;
	tenantEntityIDs: Array<number>;
	projectIDs: Array<number>;
	onComplete?: () => void;
};

export type CashflowLimitUpdateOptions = {
	typeMode: CashflowLimitsTypeModeCode;
	tenantEntityID: number;
	projectID: number;
	accountChartItemID: number;
	periodType: CashflowLimitPeriod;
	periodID: number;
	value?: number;
	plannedOperationIDs?: Array<number>;
};

export type CashflowLimitPeriod = 'DAY' | 'WEEK' | 'MONTH';

export { cashflowLimitsApi };
