import { createTypes } from '@flux';

type ActionTypes = {
	INVALIDATE_DETAILS_OPERATIONS: string;
	INVALIDATE_CASHFLOW_LIMITS: string;
	SET_DATE_RANGE: string;
	SET_TENANT_ENTITIES_FILTER: string;
	SET_PROJECTS_FILTER: string;
	SET_SEARCH_TEXT: string;
	SET_GRANULATION_MODE: string;
	SET_TYPE_MODE: string;
	SET_IS_QUARTERS_VISIBLE: string;
	SET_IS_YEARS_VISIBLE: string;
	SET_IS_FACT_VISIBLE: string;
	SET_IS_DEVIATION_VISIBLE: string;
	SET_IS_HEADER_COLLAPSED: string;
	SET_IS_MANUAL_MODE: string;
	SET_EXPANDED_HIERARCHY_MAP: string;
	SET_EXPANDED_COLUMNS_MAP: string;
	SET_SELECTED_COORDS: string;
	SET_LIMIT_CREATE_OPTIONS: string;
	SET_DETAILS_OPERATIONS_OPTIONS: string;
	SET_DETAILS_OPERATIONS_TITLE: string;
	RESTORE_CASHFLOW_LIMITS_VALIDATION: string;
	RESTORE_DETAILS_OPERATIONS_VALIDATION: string;
	SET_CASHFLOW_LIMITS: string;
	FETCH_CASHFLOW_LIMITS: string;
	FETCH_DETAILS_OPERATIONS: string;
	SET_UNSAVED_CELLS: string;
	SET_UNSAVED_CELLS_LENGTH: string;
	SET_HAS_UNFINISHED_QUEUE: string;
};

export const types = createTypes<ActionTypes>(
	[
		'INVALIDATE_DETAILS_OPERATIONS',
		'INVALIDATE_CASHFLOW_LIMITS',
		'SET_DATE_RANGE',
		'SET_TENANT_ENTITIES_FILTER',
		'SET_PROJECTS_FILTER',
		'SET_SEARCH_TEXT',
		'SET_GRANULATION_MODE',
		'SET_TYPE_MODE',
		'SET_IS_QUARTERS_VISIBLE',
		'SET_IS_YEARS_VISIBLE',
		'SET_IS_FACT_VISIBLE',
		'SET_IS_DEVIATION_VISIBLE',
		'SET_IS_HEADER_COLLAPSED',
		'SET_IS_MANUAL_MODE',
		'SET_EXPANDED_HIERARCHY_MAP',
		'SET_EXPANDED_COLUMNS_MAP',
		'SET_SELECTED_COORDS',
		'SET_LIMIT_CREATE_OPTIONS',
		'SET_DETAILS_OPERATIONS_OPTIONS',
		'SET_DETAILS_OPERATIONS_TITLE',
		'RESTORE_CASHFLOW_LIMITS_VALIDATION',
		'RESTORE_DETAILS_OPERATIONS_VALIDATION',
		'SET_CASHFLOW_LIMITS',
		'FETCH_CASHFLOW_LIMITS',
		'FETCH_DETAILS_OPERATIONS',
		'SET_UNSAVED_CELLS',
		'SET_UNSAVED_CELLS_LENGTH',
		'SET_HAS_UNFINISHED_QUEUE',
	],
	'CASHFLOW_LIMITS',
);
